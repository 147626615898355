 
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WrongRouteComponent } from './components/auth/errors/wrong-route/wrong-route.component';
import { LoginComponent } from './components/auth/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { CategoryModule } from './components/category/category.module';
import { OrdersModule } from './components/orders/orders.module';
import { ProviderModule } from './components/brands/provider.module';
import { CountryModule } from './components/country/country.module';
import { CityModule } from './components/city/city.module';
import { SubcategoriesModule } from './components/subcategories/subcategories.module';
import { ModelsModule } from './components/car_models/models.module';
import { BannersModule } from './components/banners/banners.module';
import { OccasionsModule } from './components/yearofcreation/occasions.module';
import { TermsModule} from './components/terms-and-conditions/terms.module'
import { ProductsModule } from './components/products/products.modules';
import { FooterModule } from './components/footer/footer.module';
import { ReviewsModule } from './components/reviews/reviews.modules';
import { CouponModule } from './components/coupon/coupon.module';
import { ShippingFeeModule } from './components/shipping-fee/shipping-fee.module';
import { UsersModule } from './components/users/users.module';
import { ValiditiesModule } from './components/validities/validity.module';
const routes: Routes = [
  {path:'',component:LoginComponent},
  {path:'auth/login',component:LoginComponent},
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
      {path:'home',component:HomeComponent },   //  {path:'home',component:HomeComponent  , data: { title: 'الإحصائيات' }}  
    ]
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
     
      {path:'brands',loadChildren:()=>ProviderModule},
      {path:'orders',loadChildren:()=>OrdersModule},
      {path:'country',loadChildren:()=>CountryModule},
      {path:'city',loadChildren:()=>CityModule},
      {path:'services',loadChildren:()=>CategoryModule},
      {path:'sub',loadChildren:()=>SubcategoriesModule},
      {path:'car-models',loadChildren:()=>ModelsModule},
      {path:'banners',loadChildren:()=>BannersModule},
      {path:'yearofcreation',loadChildren:()=>OccasionsModule},
      {path:'terms',loadChildren:()=>TermsModule},
      {path:'products',loadChildren:()=>ProductsModule},
      {path:'footer',loadChildren:()=>FooterModule},
      {path:'reviews',loadChildren:()=>ReviewsModule},
      {path:'coupon',loadChildren:()=>CouponModule},
      {path:'shipping',loadChildren:()=>ShippingFeeModule},
      {path:'users',loadChildren:()=>UsersModule},
      {path:'validities',loadChildren:()=>ValiditiesModule},
    ]
  },
  {
    path        : '**',
    pathMatch   : 'full',
    component   : WrongRouteComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
