import { Component, OnInit } from '@angular/core';
import   Swal  from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/global.service';
import { EditUsersComponent } from '../edit-users/edit-users.component';
@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit {

  permissions:any=[];
  baseUrl=environment.baseURL;
  constructor(
    private dialog:MatDialog,
    private spinner:NgxSpinnerService,
    private service:GlobalService
    ) { }

  ngOnInit(): void {
    this.allUsersPermissions()
  }

  allUsersPermissions(){
    this.service.showAllSubdmainPermissions().subscribe((res:any)=>{
      this.permissions=res['data']
      console.log("allPermissions",this.permissions)
    })
     
  }
  
  deletePermission(permisions_id){
    this.spinner.show()
    this.service.deleteAllSubdmainPermissions(permisions_id).subscribe(res=>{
      this.spinner.hide()
      Swal.fire(
        'نجاح',
        'تم حذف المستخدم بنجاح',
        'success'
        )
        this.allUsersPermissions()
      })
    
  }

  editPermission(city){
    let dialogRef = this.dialog.open(EditUsersComponent, {
      data:city,
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.allUsersPermissions()
    });
  }


}
