import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/components/auth/authentication.service';
declare var $,jQuery:any

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public a =[]
  public b = []
  user:any
  constructor( private authenticationService:AuthenticationService,private service:GlobalService, private spinner:NgxSpinnerService,private router:Router ,private route: ActivatedRoute ) {
    this.authenticationService.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
    console.log("this.user" ,this.user.data.user.permissions)
    if(this.user.data.user.permissions!=null){
      this.a = this.user.data.user.permissions
    }
    else {
      this.a =[
        { uid:"1", name:"الإحصائيات", add:"null" , add_route:'/home', list:"null" , list_route:'/home',toggle_icon:'false'},
        { uid:"2", name:"المدن", add:"إضافة مدينة" , add_route: '/app/city/add', list:"قائمة المدن" , list_route:'/app/city/list',toggle_icon:'false'},
        { uid:"3", name:"الأقسام الرئيسية", add:"إضافة قسم رئيسي" , add_route: '/app/services/add', list:"قائمة الأقسام الرئيسية" , list_route:'/app/services/list' ,toggle_icon:'false'}, 
        { uid:"4", name:"الأقسام الفرعية", add:"إضافة قسم فرعي" , add_route: '/app/sub/add', list:"قائمة الأقسام الفرعية" , list_route:'/app/sub/list' ,toggle_icon:'false'}, //id
        { uid:"5", name:"البراندات", add:"إضافة براند" , add_route: '/app/brands/add', list:"قائمة البراندات" , list_route: '/app/brands/list' ,toggle_icon:'false'},
        { uid:"6", name:"الموديلات", add:"إضافة موديل" , add_route: '/app/car-models/add', list:"قائمة الموديلات" , list_route:'/app/car-models/list/',toggle_icon:'false'},
        { uid:"7", name:"سنوات الصنع", add:"إضافة سنة صنع" , add_route: '/app/yearofcreation/add', list:"قائمة سنوات الصنع" , list_route:'/app/yearofcreation/list',toggle_icon:'false'},
  
        { uid:"8", name:"المنتجات", add:"إضافة منتج" , add_route: '/app/products/add', list:"إضافة منتجات" , list_route:'/app/products/addexcel', extra_list:"جميع المنتجات" , toggle_icon:'false'},
  
        { uid:"9", name:"الطلبات", add:"جميع الطلبات", add_route: '/app/orders/list', list:"الطلبات السريعة" , list_route:'/app/orders/special',toggle_icon:'false'},
        { uid:"10", name:"البنارات", add:"إضافة بانر" , add_route: '/app/banners/add', list:"قائمة البنارات" , list_route:'/app/banners/list',toggle_icon:'false'},
        { uid:"11", name:"آراء العملاء", add:"إضافة آراء العملاء" , add_route: '/app/reviews/add', list:"قائمة آراء العملاء" , list_route:'/app/reviews/list',toggle_icon:'false'},
        { uid:"12", name:"الكوبون", add:"إضافة كوبون" , add_route: '/app/coupon/add', list:"قائمة الكوبونات" , list_route:'/app/coupon/list',toggle_icon:'false'},
        { uid:"13", name:"الضريبة و مصاريف الشحن ", add:"إضافة ضريبة و مصاريف الشحن" , add_route: '/app/shipping/add', list:"قائمة الضريبة و مصاريف الشحن" , list_route:'/app/shipping/list',toggle_icon:'false'},
        { uid:"14", name:"بيانات الموقع", add:"إضافة بيانات" , add_route: '/app/footer/add', list:"تعديل البيانات" , list_route:'/app/footer/edit' ,toggle_icon:'false'},
        { uid:"15", name:"صلاحيات المستخدمين", add:"إضافة صلاحيات مستخدم" , add_route: '/app/users/add', list:"قائمة صلاحيات المستخدمين" , list_route:'/app/users/list' ,toggle_icon:'false'},
        { uid:"16", name:"الصلاحيات", add:"إضافة صلاحية" , add_route: '/app/validities/add', list:"قائمة الصلاحيات" , list_route:'/app/validities/list' ,toggle_icon:'false'}
      ];
    }
      
      this.b =
     [ 
      { uid:"1", name:"الإحصائيات", add:"null" , add_route:'/home', list:"null" , list_route:'/home',toggle_icon:'false'},
      { uid:"2", name:"المدن", add:"إضافة مدينة" , add_route: '/app/city/add', list:"قائمة المدن" , list_route:'/app/city/list',toggle_icon:'false'},
      { uid:"3", name:"الأقسام الرئيسية", add:"إضافة قسم رئيسي" , add_route: '/app/services/add', list:"قائمة الأقسام الرئيسية" , list_route:'/app/services/list' ,toggle_icon:'false'}, 
      { uid:"4", name:"الأقسام الفرعية", add:"إضافة قسم فرعي" , add_route: '/app/sub/add', list:"قائمة الأقسام الفرعية" , list_route:'/app/sub/list' ,toggle_icon:'false'}, //id
      { uid:"5", name:"البراندات", add:"إضافة براند" , add_route: '/app/brands/add', list:"قائمة البراندات" , list_route: '/app/brands/list' ,toggle_icon:'false'},
      { uid:"6", name:"الموديلات", add:"إضافة موديل" , add_route: '/app/car-models/add', list:"قائمة الموديلات" , list_route:'/app/car-models/list/',toggle_icon:'false'},
      { uid:"7", name:"سنوات الصنع", add:"إضافة سنة صنع" , add_route: '/app/yearofcreation/add', list:"قائمة سنوات الصنع" , list_route:'/app/yearofcreation/list',toggle_icon:'false'},

      { uid:"8", name:"المنتجات", add:"إضافة منتج" , add_route: '/app/products/add', list:"إضافة منتجات" , list_route:'/app/products/addexcel', extra_list:"جميع المنتجات" , toggle_icon:'false'},

      { uid:"9", name:"الطلبات", add:"جميع الطلبات", add_route: '/app/orders/list', list:"الطلبات السريعة" , list_route:'/app/orders/special',toggle_icon:'false'},
      { uid:"10", name:"البنارات", add:"إضافة بانر" , add_route: '/app/banners/add', list:"قائمة البنارات" , list_route:'/app/banners/list',toggle_icon:'false'},
      { uid:"11", name:"آراء العملاء", add:"إضافة آراء العملاء" , add_route: '/app/reviews/add', list:"قائمة آراء العملاء" , list_route:'/app/reviews/list',toggle_icon:'false'},
      { uid:"12", name:"الكوبون", add:"إضافة كوبون" , add_route: '/app/coupon/add', list:"قائمة الكوبونات" , list_route:'/app/coupon/list',toggle_icon:'false'},
      { uid:"13", name:"الضريبة و مصاريف الشحن ", add:"إضافة ضريبة و مصاريف الشحن" , add_route: '/app/shipping/add', list:"قائمة الضريبة و مصاريف الشحن" , list_route:'/app/shipping/list',toggle_icon:'false'},
      { uid:"14", name:"بيانات الموقع", add:"إضافة بيانات" , add_route: '/app/footer/add', list:"تعديل البيانات" , list_route:'/app/footer/edit' ,toggle_icon:'false'},
      { uid:"15", name:"صلاحيات المستخدمين", add:"إضافة صلاحيات مستخدم" , add_route: '/app/users/add', list:"قائمة صلاحيات المستخدمين" , list_route:'/app/users/list' ,toggle_icon:'false'},
      { uid:"16", name:"الصلاحيات", add:"إضافة صلاحية" , add_route: '/app/validities/add', list:"قائمة الصلاحيات" , list_route:'/app/validities/list' ,toggle_icon:'false'}
    ];
     
  this.myList=this.compareArrays(this.b,this.a)
  console.log("myList", this.myList)
  }
  categories:any =[]
 public category_param :any =[]
  brands:any=[]
  brand_param:any=[]
  models:any=[]
  model_param:any=[] ;
  subcategories:any=[]
  sub_param:any=[] ;
  status=false
   public myList=[] ;
  ngOnInit(): void {
      // left sidebar and vertical menu
      if ($('#pageWrapper').hasClass('compact-wrapper')) {
        jQuery('.sidebar-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
        jQuery('.sidebar-title').click(function () {
            jQuery('.sidebar-title').removeClass('active').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.sidebar-submenu, .menu-content').slideUp('normal');
            jQuery('.menu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            }
        });
        jQuery('.sidebar-submenu, .menu-content').hide();
        jQuery('.submenu-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
        jQuery('.submenu-title').click(function () {
            jQuery('.submenu-title').removeClass('active').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.submenu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            }
        });
        jQuery('.submenu-content').hide();
      } else if ($('#pageWrapper').hasClass('horizontal-wrapper')) {
        var contentwidth = jQuery(window).width();
        if ((contentwidth) < '992') {
            $('#pageWrapper').removeClass('horizontal-wrapper').addClass('compact-wrapper');
            $('.page-body-wrapper').removeClass('horizontal-menu').addClass('sidebar-icon');
            jQuery('.submenu-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.submenu-title').click(function () {
                jQuery('.submenu-title').removeClass('active');
                jQuery('.submenu-title').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                jQuery('.submenu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                }
            });
            jQuery('.submenu-content').hide();
  
            jQuery('.sidebar-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.sidebar-title').click(function () {
                jQuery('.sidebar-title').removeClass('active');
                jQuery('.sidebar-title').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                jQuery('.sidebar-submenu, .menu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                }
            });
            jQuery('.sidebar-submenu, .menu-content').hide();
        }
      }

  
      this.categoryList()
      this.getAllBrands()

     
    }

    showStatistics(id:any , status:boolean){
      if(id==1 && status==true){
        this.router.navigate(['/home'])
      }
      else  if(id==8 && status==false){
        this.router.navigate(['/app/products/lists',this.category_param[0],this.sub_param[0],this.brand_param[0],this.model_param[0]]);
      }
     }

   compareArrays(arrfirst, arrsecond) {
    var props = ['uid','name','add','add_route','list','list_route','toggle_icon' ,'extra_list'];
    var result = arrfirst.filter(function(o1){
    return arrsecond.some(function(o2){
       return o1.uid === o2.uid;        
     });
      }).map(function(o){
    
     return props.reduce(function(newo, name){
       newo[name] = o[name];
       return newo;
       }, {});
    });
    console.log("result",result)
     return result ;
    }

   onClickBTN(){
    this.status=!this.status
    var targetDiv = document.getElementsByClassName("sidebar-wrapper")[0] as HTMLElement  ;
    var targetDiv2 = document.getElementsByClassName("sidebar-main")[0] as HTMLElement  ;
    var targetBody = document.getElementsByClassName("page-body")[0] as HTMLElement  ;
    var targetBTN = document.getElementById("toggle-sidebar")  as HTMLElement ;
    var targetHeader = document.getElementsByClassName("page-header")[0] as HTMLElement  ;
    var targetFooter = document.getElementsByClassName("footer")[0] as HTMLElement  ;
    var targetFooter = document.getElementsByClassName("footer")[0] as HTMLElement  ;
  
     
    if(this.status==true){
      targetDiv.style.width='280px';
      targetHeader.style.marginRight='280px';
      targetBody.style.marginRight='280px';
      targetFooter.style.marginRight='280px';
      targetBTN.style.left='30px';
      targetDiv2.style.display='block';
      targetHeader.style.width='calc(100%-280px)';
     }
    else {
      targetDiv.style.width='35px';
      targetBTN.style.left='5px';
      targetDiv2.style.display='none';
      targetBody.style.marginRight='0px';
      targetFooter.style.marginRight='0px';
      targetHeader.style.marginRight='0px';
      targetHeader.style.width='100%';
     }
    }
    //get all categories
  categoryList(){
    this.service.allCategories().subscribe((res:any)=>{
      this.categories = res['data']
      for(let i=0 ; i<this.categories.length ;i++){
        if(this.categories[i].subcategories.length!=0){
          this.category_param[0]=this.categories[i].id
          console.log("this.category_param" , this.category_param)
          break ;
        }
       
      }
       this.getAllSubcategories(this.category_param)
    })
  }
 
  getAllSubcategories(category_id){
    this.service.getSubcategoryByCategoryId(category_id).subscribe((res:any)=>{
      this.subcategories=res['data']
   //   this.subcategories=[...this.subcategories].reverse()
   //   console.log("All SubCategories" , this.subcategories)
     this.sub_param[0]=this.subcategories[0].id
     return this.sub_param
     })
  }

  getAllBrands(){
    this.service.getBrands().subscribe((res:any)=>{ 
      this.brands=res['data'] ;
 
   //   console.log("All Brands" , this.brands)
      this.brand_param[0]= this.brands[0].id;
      this.getAllModels(this.brand_param)
    })
  }

  getAllModels(brand_id){
    this.service.getModelsByBrandId(brand_id).subscribe((res:any)=>{
      this.models=res['data']
  
     // console.log("All Models" , this.models)
      this.model_param[0]=this.models[0].id
      return this.model_param
     })
  }

  // goListProducts(){
  //   //console.log("SSS",this.category_param[0],this.sub_param[0],this.brand_param[0],this.model_param[0])
  //    this.router.navigate(['/app/products/lists',this.category_param[0],this.sub_param[0],this.brand_param[0],this.model_param[0]]);
  //    //this.router.navigate(['/app/products/lists',0,0,0,0]);
  //  }
   
}
