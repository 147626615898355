import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-reviews',
  templateUrl: './edit-reviews.component.html',
  styleUrls: ['./edit-reviews.component.scss']
})
export class EditReviewsComponent implements OnInit {

  image_edit:boolean=false ;
  form:FormGroup;
  submitted:boolean=false ;
 constructor(
   private formbuilder:FormBuilder,
   private service:GlobalService,
   private spinner:NgxSpinnerService,
   private router:Router,
   private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,
   ) { }

 ngOnInit(): void {
 // console.log("edit reviews",this.data)
  this.form=this.formbuilder.group({
    name:[this.data.name,Validators.required],
    country:[this.data.country,Validators.required],
    review:[this.data.review,Validators.required],
    rating:[this.data.rating,Validators.required],
  })
 }
 files: File[] = [];

  onSelect(event) {
    this.files=[]
    this.files.push(...event.addedFiles);

    event.addedFiles.forEach(file=>{
      console.log("fileSize",file,file.size)
      if(file.size>1048576) // 1MB 
      {
        Swal.fire( 
          `Fail`,
          `Image size must be 1 megabytes or less `,
          `error`)
      }
     })
  }
  
  onRemove(event) {
  //  console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
   
  get f() {return this.form.controls}
  
    submit(){
      this.submitted=true
      let f={
        home_review_id:this.data.id,
        name:this.form.value.name,
        country:this.form.value.country,
        review:this.form.value.review,
        rating:this.form.value.rating,
        image:this.files[0],
      }
      this.spinner.show()
     // console.log('the sended form is ',typeof(this.files[0]))
      this.service.editHomeReviews(f).subscribe(res=>{ 
         this.spinner.hide()
        if(res['status']==true) {
       //   console.log(res)
      
          Swal.fire(
              'نجاح',
              `${res['message']}`,
              'success'
            )
            this.dialog.closeAll()
            this.router.navigate(['/app/reviews/list'])
        }
        else {
          let error =res['errors']
          Swal.fire(
            'خطأ',
           `${error[0]}`,
            'error'
          )
        }
      })
    }
    
}
