import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {
  form:FormGroup;
  permissions:any=[];
  permissions_arr:any=[];
  image_edit=false;
  baseUrl=environment.baseURL;
  submitted:boolean = false
  constructor(
    private formbuilder:FormBuilder,
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    private router:Router,
    
  ) { this.permissionsList()}
  
  ngOnInit(): void {
    this.form=this.formbuilder.group({
      name:[null,Validators.required],
      email:[null,Validators.required],
      password:[null,Validators.required] 
     })
   }

  permissionsList(){
    this.spinner.show()
    this.service.showAllPermissions().pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()
    this.permissions=res
    })
  }
 
  onChangePermissions(event:any){
    this.permissions_arr=event.value
     console.log("events",this.permissions_arr)
  }
  get f() {
    return this.form.controls;
  }
  submit(){
    this.submitted=true;
    let form={
      name :this.form.value.name  ,
      email:this.form.value.email ,
      password:this.form.value.password ||null ,
      permissions_ids:this.permissions_arr ,
    }
    console.log("form",form)
    if(this.form.invalid){return}
    this.spinner.show()
    this.service.addAllSubdmainPermissions(form).subscribe((res:any)=>{
    if(res.status==true) { 
      this.spinner.hide()
      Swal.fire(
          'نجاح',
          'تم إضافة بيانات المستخدم بنجاح',
          'success'
        )
     }else {
      this.spinner.hide()
      Swal.fire(
          'فشل',
          `${res.errors[0]}`,
          'error'
        )
     } },
    (err:any)=>{
      console.log("EEEEEE",err)
      this.spinner.hide()
      Swal.fire(
          'فشل',
          'لم تتم إضافة بيانات المستخدم بنجاح',
          'error'
        )
    })
  }
}
