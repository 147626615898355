import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddReviewsComponent } from './add-reviews/add-reviews.component';
import { ListReviewsComponent } from './list-reviews/list-reviews.component';
 

const routes: Routes = [
  {path:'list',component:ListReviewsComponent,data:{title:'قائمة آراء العملاء'}},
  {path:'add',component:AddReviewsComponent,data:{title:' إضافة آراء العملاء'}},
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewsRoutingModule { }
