import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCityComponent } from './add/add.component';
import { ListCityComponent } from './list/list.component';

const routes: Routes = [
  {path:'list',component:ListCityComponent,data:{title:'قائمة المدن'}},
  {path:'add',component:AddCityComponent,data:{title:' إضافة مدينة'}},
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CityRoutingModule { }
