import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit ,Inject  } from '@angular/core';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
 
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  form:FormGroup;
  // private imageCompress: NgxImageCompressService ,
  constructor(
    private formbuilder:FormBuilder,
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    private router:Router,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { }

  ngOnInit(): void {
      //console.log("Update Banner", this.data)
  }

  files: File[] = [];

onSelect(event) {
 // console.log(event.addedFiles);
  // this.files=[]
  this.files.push(...event.addedFiles);
  event.addedFiles.forEach(file=>{
    console.log("fileSize",file,file.size)
    if(file.size>1048576) // 1MB 
    {
      Swal.fire( 
        `Fail`,
        `Image size must be 1 megabytes or less `,
        `error`)
    }
   })
}

onRemove(event) {
  //console.log(event);
  this.files.splice(this.files.indexOf(event), 1);
}

  submit(){
     let  form ;
    //console.log("FFFF", form)
   
    if(this.data.type=='update'){
      form = {
        files:this.files[0]
       }
    //  this.data.images.push()
    let updated
 
    this.service.uploadFiles(form).subscribe((res:any)=>{
     //console.log("convert image to link " ,res['data'])
       updated=res['data'] 
     //  console.log("DDDDDD",updated)
       this.data.images.push(...updated)
       let f={
        image:this.data.images
       }

       this.spinner.show();
       this.service.editBanner(f).subscribe(res=>{
       this.spinner.hide();
       
        if(res['status']) {
         Swal.fire(
           'نجاح',
           'تم تعديل البانر بنجاح',
           'success'
         )
         //  this.router.navigate(['/app/banners/list'])
          this.dialog.closeAll()
   
        }
        else {
         let error=res['errors']
         Swal.fire(
           'خطأ',
            `${ error[0]}`,
           'error'
         )
        }
      
        })
      })
    
    }
    else{
      form = {
        image:this.files
       }
       this.spinner.show();
      this.service.addBanner(form).subscribe(res=>{
      this.spinner.hide()
        if(res['status'] ==true){
           Swal.fire(
              'نجاح',
              'تم إضافة البانر بنجاح',
              'success'
            )
       //     this.router.navigate(['/app/banners/list'])
        }
        else {
          let error =res['errors']
          Swal.fire(
            'خطأ',
              `${error[0] }`,
            'error'
          )
        }
      })
    }
 
  }

}
