import { Component,Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.scss']
})
export class EditCouponComponent implements OnInit {
  form:FormGroup;
  coupon=[];
  submitted=false;
  constructor(
    private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,
    ) { }


  ngOnInit(): void {
    this.form=this.formbuilder.group({
      code:[this.data.code,Validators.required],
      discount_percent:[this.data.discount_percent ,Validators.required],
      expiration_date:[this.data.expiration_date ,Validators.required],
   })
  
  }

  couponList(){
    this.service.allCoupons().subscribe(res=>{
   //   console.log("Tax_FeeList",res)
      this.coupon=res['data'] ;
      this.coupon=[...this.coupon].reverse()
    })
  }

  get f(){return this.form.controls}

  submit(){
    let f ={
      coupon_id:this.data.id,
      ...this.form.value
    }
    this.submitted=true;
    if(this.form.invalid){ return}
    this.spinner.show()
    this.service.editCoupon(f).subscribe(res=>{
      this.spinner.hide()
      if(res['status']==true){
        Swal.fire(
          'نجاح',
          `${res['message']}`,
          'success'
          )
         this.couponList()
         this.dialog.closeAll()
      }
      
      else {
        let error=res['errors']
        Swal.fire(
          'خطأ',
          `${error[0]}`,
          'error'
          )
      }
   
    })
  }
}
