import { Component, OnInit } from '@angular/core';
import { EditValidityComponent } from '../edit-validity/edit-validity.component';
import   Swal  from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-list-validity',
  templateUrl: './list-validity.component.html',
  styleUrls: ['./list-validity.component.scss']
})
export class ListValidityComponent implements OnInit {
  permissions:any=[];
  baseUrl=environment.baseURL;
  constructor(
    private dialog:MatDialog,
    private spinner:NgxSpinnerService,
    private service:GlobalService
    ) { }

  ngOnInit(): void {
    this.allPermissions()
  }

  allPermissions(){
    this.service.showAllPermissions().subscribe((res:any)=>{
      this.permissions=res['data']
      console.log("allPermissions",this.permissions)
    })
     
  }
  
  deletePermission(permisions_id){
    this.spinner.show()
    this.service.deleteAllSubdmainPermissions(permisions_id).subscribe(res=>{
      this.spinner.hide()
      Swal.fire(
        'نجاح',
        'تم حذف الصلاحية بنجاح',
        'success'
        )
        this.allPermissions()
      })
    
  }

  editPermission(permission){
    let dialogRef = this.dialog.open(EditValidityComponent, {
      data:permission,
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.allPermissions()
    });
  }


}
