import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddShippingFeeComponent } from './add-shipping-fee/add-shipping-fee.component';
import { ListShippingFeeComponent } from './list-shipping-fee/list-shipping-fee.component';
 

const routes: Routes = [
  {path:'list',component:ListShippingFeeComponent,data:{title:'قائمة إضافة الضريبة و مصاريف الشحن  '}},
  {path:'add',component:AddShippingFeeComponent,data:{title:' إضافة  الضريبة و مصاريف الشحن '}},
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShippingFeeRoutingModule { }
