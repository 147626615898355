import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 

import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSelectModule} from '@angular/material/select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { ValiditiesRoutingModule } from './validity-routing.module';
import { AddValidityComponent } from './add-validity/add-validity.component';
import { EditValidityComponent } from './edit-validity/edit-validity.component';
import { ListValidityComponent } from './list-validity/list-validity.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
@NgModule({
  declarations: [ListValidityComponent,AddValidityComponent, EditValidityComponent],
  imports: [
    CommonModule,
    ValiditiesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    MatPaginatorModule ,
    MatFormFieldModule  ,
    MatInputModule,
    MatDialogModule ,
    MatCheckboxModule
  ]
})
export class ValiditiesModule { }