import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-validity',
  templateUrl: './add-validity.component.html',
  styleUrls: ['./add-validity.component.scss']
})
export class  AddValidityComponent implements OnInit {
  form:FormGroup;
  submitted:boolean=false ;
  selected:any=[];
  uids:any=[];
  names:any=[]
  permissions:any =
  [ 
   { uid:"0", name:"الرئيسية", add:"null" , add_route:'/home', list:"null" , list_route:'/home',toggle_icon:'false'},
   { uid:"1", name:"الإحصائيات", add:"null" , add_route:'/home', list:"null" , list_route:'/home',toggle_icon:'false'},
   { uid:"2", name:"المدن", add:"إضافة مدينة" , add_route: '/app/city/add', list:"قائمة المدن" , list_route:'/app/city/list',toggle_icon:'false'},
   { uid:"3", name:"الأقسام الرئيسية", add:"إضافة قسم رئيسي" , add_route: '/app/services/add', list:"قائمة الأقسام الرئيسية" , list_route:'/app/services/list' ,toggle_icon:'false'}, 
   { uid:"4", name:"الأقسام الفرعية", add:"إضافة قسم فرعي" , add_route: '/app/sub/add', list:"قائمة الأقسام الفرعية" , list_route:'/app/sub/list' ,toggle_icon:'false'}, //id
   { uid:"5", name:"البراندات", add:"إضافة براند" , add_route: '/app/brands/add', list:"قائمة البراندات" , list_route: '/app/brands/list' ,toggle_icon:'false'},
   { uid:"6", name:"الموديلات", add:"إضافة موديل" , add_route: '/app/car-models/add', list:"قائمة الموديلات" , list_route:'/app/car-models/list/',toggle_icon:'false'},
   { uid:"7", name:"سنوات الصنع", add:"إضافة سنة صنع" , add_route: '/app/yearofcreation/add', list:"قائمة سنوات الصنع" , list_route:'/app/yearofcreation/list',toggle_icon:'false'},

   { uid:"8", name:"المنتجات", add:"إضافة منتج" , add_route: '/app/products/add', list:"إضافة منتجات" , list_route:'/app/products/addexcel', extra_list:"جميع المنتجات" , toggle_icon:'false'},

   { uid:"9", name:"الطلبات", add:"جميع الطلبات", add_route: '/app/orders/list', list:"الطلبات السريعة" , list_route:'/app/orders/special',toggle_icon:'false'},
   { uid:"10", name:"البنارات", add:"إضافة بانر" , add_route: '/app/banners/add', list:"قائمة البنارات" , list_route:'/app/banners/list',toggle_icon:'false'},
   { uid:"11", name:"آراء العملاء", add:"إضافة آراء العملاء" , add_route: '/app/reviews/add', list:"قائمة آراء العملاء" , list_route:'/app/reviews/list',toggle_icon:'false'},
   { uid:"12", name:"الكوبون", add:"إضافة كوبون" , add_route: '/app/coupon/add', list:"قائمة الكوبونات" , list_route:'/app/coupon/list',toggle_icon:'false'},
   { uid:"13", name:"الضريبة و مصاريف الشحن ", add:"إضافة ضريبة و مصاريف الشحن" , add_route: '/app/shipping/add', list:"قائمة الضريبة و مصاريف الشحن" , list_route:'/app/shipping/list',toggle_icon:'false'},
   { uid:"14", name:"بيانات الموقع", add:"إضافة بيانات" , add_route: '/app/footer/add', list:"تعديل البيانات" , list_route:'/app/footer/edit' ,toggle_icon:'false'},
   { uid:"15", name:"المستخدمين", add:"إضافة مستخدم" , add_route: '/app/users/add', list:"قائمة المستخدمين" , list_route:'/app/users/list' ,toggle_icon:'false'},
   { uid:"16", name:"الصلاحيات", add:"إضافة صلاحية" , add_route: '/app/validities/add', list:"قائمة الصلاحيات" , list_route:'/app/validities/list' ,toggle_icon:'false'}
 ];
 
  constructor( 
    private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private router:Router) { }
    
  ngOnInit(): void {
  //   this.form=this.formbuilder.group({
  //     names: this.formbuilder.array([this.formbuilder.control('') ]),
  //     uids: this.formbuilder.array([this.formbuilder.control('') ]),
  //  })
  }

  deleted_uid:any=[]
  deleted_element:any
  
  permissionCheckValue(event,permission){
    let is_checked:boolean ;
    is_checked=event.checked;
    if(is_checked==true){
      this.uids.push(permission.uid)
      // this.names.push(permission.name)
      }
      else {
          for(let i=0 ; i<this.uids.length ; i++){
            if(this.uids[i]==permission.uid){
              this.deleted_element=this.uids[i]
              this.uids.splice(i,1)
              // this.names.splice(i,1)
           }
        }
        this.deleted_uid.push(this.deleted_element)
      }

      
    console.log("deleted uids",this.deleted_uid) 
    console.log("uids",this.uids)
    //  console.log("names",this.names)
  } 

 
  submit(){
    let form={
      names:this.names,
      uids:this.uids,
    }
    this.spinner.show()
    this.service.addAllPermissions(form).subscribe((res:any)=>{
    this.spinner.hide()
      console.log(" addAllPermissions",res)
      Swal.fire(
        'نجاح',
        'تم إضافة الصلاحيات بنجاح',
        'success'
      )
    })
  }
}
