import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';
 
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  image_edit:boolean=false
  selected:boolean=false;
  constructor(  private service:GlobalService,
    private spinner:NgxSpinnerService,private router:Router,private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
    //console.log("data",this.data)
  }
  files: File[] = [];
  d_images:any =[];
  onSelect(event) {
    this.selected=true
   // console.log(event.addedFiles[0]);
    //this.files=[]
    this.files.push(...event.addedFiles);
    event.addedFiles.forEach(file=>{
      console.log("fileSize",file,file.size)
      if(file.size>1048576) // 1MB 
      {
        Swal.fire( 
          `Fail`,
          `Image size must be 1 megabytes or less `,
          `error`)
      }
     })
    let images_form = {
      files:this.files
    }
    
    this.service.uploadFiles(images_form).subscribe((res:any)=>{
     // console.log("convert image to link " ,res['data'])
      this.d_images=res['data'] 
      
    })
  }
 
  onRemove(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  submit(){
   // console.log("selected",this.selected )
    let form
    if (this.selected==false){
      Swal.fire(
        'نجاح',
        'لم يحدث تغيير للبانر  ',
        'success'
      )
      
    }
    else {
      form = {
        image:this.d_images, 
        }
     // console.log("form",form)
     this.spinner.show()
     this.service.editBanner(form).subscribe(res=>{
     this.spinner.hide()
     if(res['status']) {
      Swal.fire(
        'نجاح',
        'تم تعديل البانر بنجاح',
        'success'
      )
      //  this.router.navigate(['/app/banners/list'])
       this.dialog.closeAll()

     }
     else {
      let error=res['errors']
      Swal.fire(
        'خطأ',
         `${ error[0]}`,
        'error'
      )
     }
   
     })
    }
  }
}
