import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCouponComponent } from './add-coupon/add-coupon.component';
import { ListCouponComponent } from './list-coupon/list-coupon.component';
 

const routes: Routes = [
  {path:'list',component:ListCouponComponent,data:{title:'قائمة عرض الكوبون'}},
  {path:'add',component:AddCouponComponent,data:{title:' إضافة كوبون'}},
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponRoutingModule { }
