import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUsersComponent } from './add-users/add-users.component';
import { EditUsersComponent } from './edit-users/edit-users.component';
import { ListUsersComponent } from './list-users/list-users.component';
 

const routes: Routes = [
  {path:'add',component:AddUsersComponent,data:{title:'إضافة بيانات المستخدم'}},
  {path:'edit',component:EditUsersComponent,data:{title:'تعديل بيانات المستخدم'}},
  {path:'list',component:ListUsersComponent,data:{title:'قائمة المستخدمين'}}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }