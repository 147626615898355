import { Component, OnInit } from '@angular/core';
import   Swal  from 'sweetalert2';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { EditReviewsComponent } from '../edit-reviews/edit-reviews.component';
 
@Component({
  selector: 'app-list-reviews',
  templateUrl: './list-reviews.component.html',
  styleUrls: ['./list-reviews.component.scss']
})
export class ListReviewsComponent implements OnInit {

  flag:boolean=false
  reviews;
  baseUrl=environment.baseURL;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.reviewsList()
  }

  reviewsList(){
    this.spinner.show()
    this.service.allHomeReviews().pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()
   // console.log('all reviews' ,res)
       
      this.reviews=[...res].reverse()
    })
  }

  deleteReview(category_id){
  //  console.log(category_id)
    this.spinner.show()
    this.service.deleteHomeReviews(category_id).subscribe(res=>{
      this.spinner.hide()
      Swal.fire(
        'نجاح',
        'تم الحذف بنجاح  ',
        'success'
        )
        this.reviewsList()
    })
  }
  editReview(category){
    let dialogRef = this.dialog.open(EditReviewsComponent, {
      data:category,
      height: '600px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reviewsList()
    });
  }
  

}
