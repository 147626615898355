import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
 
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent implements OnInit {
   form:FormGroup ;
  public years:any=[];
  public models:any=[];
  public total:any=0 ;
  public brands:any=[];
  public models_brands:any=[];
  public all_brands:any=[];
  public send_year:any=[] ; 
  public send_model:any=[] ;
  public models_of_brands:any=[] ;
  public all_models:any=[];
  public all_years:any=[];
  public years_id:any=[];
  public year_str:any ;
  public categories:any=[] ;
  public all_categories:any=[];
  public subcategories_of_maincategories:any=[];
  public subCategories:any=[];
  public compatible_brands=[] ;
  public d_images:any =[]
  public images:any=[];
  image_edit=false;
  image_edit2=false;
   
 @ViewChild ('price')price:ElementRef;
 @ViewChild ('dicount')dicount:ElementRef; 

  constructor(private spinner:NgxSpinnerService,  private formbuilder:FormBuilder ,private service: GlobalService ,  private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any) { }
 
  ngOnInit(): void {
    
   // console.log("dynamic",this.data)
    this.images[0]=this.data.imagePath
    this.d_images=this.data.descriptionImagesPaths
 
    this.form=this.formbuilder.group({ 
      name:[this.data.name,Validators.required],
      price:[this.data.price,Validators.required],
      discount_percent:[this.data.discount_percent,Validators.required],
      piece_number:[this.data.piece_number,Validators.required],
      manufacture_place:[this.data.manufacture_place,Validators.required],
      description:[this.data.description,Validators.required],
      compatibles: this.formbuilder.array([])
    })
    
    this.total=this.data.price_after_discount ;
    for(let i=0; i<this.data.compatibles.length ; i++){
    this.addCompatibles();
    let control = this.form.get('compatibles')['controls'][i]
       control.controls.model.value = this.data.compatibles[i].model
       control.controls.year.value = this.data.compatibles[i].manufacture_dates
      for(let y=0 ; y<control.controls.year.value.length ; y++){
       this.years_id.push(control.controls.year.value[y].id )
      }
    //  console.log("control.controls.year.value ",  this.years_id )
      this.all_years.push(this.years_id ) ;
      this.years_id=[]
      this.year_str = this.all_years.map(a => a.join(",")).join("|");
   //    console.log("all_years",this.year_str)
      this.all_models.push(control.controls.model.value.id )

       this.compatible_brands.push(this.data.compatibles[i].model.brand_id)     
     
    }

   // console.log("compatible_brands",this.compatible_brands)
 

    for(let i=0; i<this.data.categories.length ; i++){
      this.categories[i]=this.data.categories[i].id 
    }

    this.service.getSubcategoryByCategoryId(this.categories).subscribe((res:any)=>{
    this.subcategories_of_maincategories=res['data']
    })

    for(let j=0 ; j<this.data.subcategories.length ; j++){
      this.subCategories[j]={cat:this.data.subcategories[j]?.category_id , sub:this.data.subcategories[j]?.id}
    //  console.log("subCategories",this.subCategories[j])
    }

    for(let i=0 ; i<this.data.brands.length ; i++){
      this.brands[i]=this.data.brands[i].id 
   }
 
    this.service.getModelsByBrandId(this.brands).subscribe((res:any)=>{
      this.models_of_brands=res['data']
     // console.log("mmmmmmmmmmmm ",this.models_brands)
    })

    for(let i=0 ; i<this.data.models.length; i++){
      this.models_brands[i]={cat:this.data.models[i]?.brand_id , sub:this.data.models[i]?.id}
    //  console.log("models_brands ",this.models_brands)
    }

    this.allYears();
    this.allModels(this.compatible_brands)
    this.allCategories();
    this.allBrands();
  }
 
  
  allCategories(){
    this.service.allCategories().subscribe((res:any)=>{
      this.all_categories=res['data']
     // console.log("all_categories" ,this.all_categories)
    })
  }

  onChangeCategory(event){
    this.categories=event.value  ;
   // console.log("all selected categories" ,this.categories)
    this.service.getSubcategoryByCategoryId(this.categories).subscribe((res:any)=>{
    this.subcategories_of_maincategories=res['data']
  //  console.log("subcategories_of_maincategories",this.subcategories_of_maincategories)
    })
  }

  onChangeSubCategory(event){
    this.subCategories =event.value
   //  console.log("all selected subCategories" , this.subCategories)
  }

  allBrands(){
    this.service.getBrands().subscribe((res:any)=>{
      this.all_brands=res['data']
     })
  }
  
  onChangeBrands(event){
    this.brands=event.value ;
  //  console.log("all selected Brands",  this.brands)
     this.service.getModelsByBrandId(event.value).subscribe((res:any)=>{
     this.models_of_brands=res['data']
     this.models=res['data']
   //  console.log("asdfs",this.models_of_brands)
    })
  }

  onChangeModels(event){
    this.models_brands =event.value
    //  console.log("all selected models" , this.models)
  }
  // this compare each select value in array  with *ngfor array 
  compareWith(optionOne,optionTwo){
   // console.log("optionOne,optionTwo", optionOne.sub, optionTwo.sub)
    return optionOne.sub==optionTwo.sub
   
  }

  compareCompatibles(optionOne,optionTwo){
  //console.log("optionOne,optionTwo",optionOne,optionTwo.id)
    return optionOne ==optionTwo.id 
  }

  compatible():any {
    return this.form.get('compatibles') as FormArray;
  }

  newCompatibles(): FormGroup {
    return  this.formbuilder.group({
      model:['',Validators.required],
      year :['',Validators.required],
     });
  }

  addCompatibles() {
    this.compatible().push(this.newCompatibles()); 
  }

  removeCompatibles(index: number) { 
    this.compatible().removeAt(index);
    this.send_model.splice(index, 1 );
    this.send_year.splice(index, 1 );
    this.all_years.splice(index,1);
    this.all_models.splice(index,1);
    this.year_str = this.all_years.map(a => a.join(",")).join("|");
   
  }
  
  allYears(){
    this.service.allYears().subscribe((res:any)=>{
      this.years=res['data']
     // console.log("years" ,this.years )
   })
  }

  allModels(brands){
   // console.log("EEEE",brands)
    this.service.getModelsByBrandId(brands).subscribe((res:any)=>{
      this.models=res['data']
   // console.log("models",this.models)
     })
  }
  
  onChangeModel(event ,index){
  this.send_model=event.value
   //console.log("onChangeModel",this.send_model)
   this.submittedModels(index)
  }
 
  onChangeYear(event , index){ 
   this.send_year =event.value
   //console.log("onChangeYear",this.send_year)
   this.submittedYears(index) 
  }
  
  submittedModels(index){
    if(this.send_model.length!=0){
      this.all_models[index]=this.send_model ; 
     }
  }

  submittedYears(index) {
    if(this.send_year.length!=0){
      this.all_years[index]=this.send_year;
     this.year_str = this.all_years.map(a => a.join(",")).join("|");
     } 
  }

  convertArrayOfObjToStr(arrOfobjs){
    var subv=[]
    var newArray = arrOfobjs.reduce(function(acc, curr) {
      var findIfNameExist = acc.findIndex(function(item) {
         return item.cat === curr.cat;
       })
       if (findIfNameExist === -1) {
         let obj = {
           'cat': curr.cat,
           "value": [curr.sub]
         }
         acc.push(obj)
       } 
       else {
       acc[findIfNameExist].value.push(curr.sub)
       }
       return acc;
      }, []);
   
   
     let sortedProducts = newArray.sort((s1, s2) => {
       return s1.cat - s2.cat;
     });
    
      for(var i=0 ; i<newArray.length ; i++){
        subv.push(newArray[i].value)
     }
    // console.log("subv",subv)
     let finalStr = subv.map(a => a.join(",")).join('|');
   
    return finalStr;
  }

  description_files:File[] =[] ;
  onSelectDescription_images(event) {
    this.image_edit2=true;
  //  console.log(event.addedFiles); 
    this.description_files.push(...event.addedFiles);

    event.addedFiles.forEach(file=>{
      console.log("fileSize",file.size)
      if(file.size>1048576) // 1MB 
      {
        Swal.fire( 
          `Fail`,
          `Image size must be 1 megabytes or less `,
          `error`)
      }
     })


    let images_form = {
      files:this.description_files
    }
    this.service.uploadFiles(images_form).subscribe((res:any)=>{
      this.d_images=res['data']
     console.log("description images ",this.d_images )
    })
  } 

  onRemovedDescription_images(event) {
  //  console.log(event);
    this.description_files.splice(this.description_files.indexOf(event), 1);
  }

 files:File[] = [];
  onSelect(event) {
    this.image_edit=true;
  //  console.log(event.addedFiles);
    this.files=[]
    this.files.push(...event.addedFiles);
    event.addedFiles.forEach(file=>{
      console.log("fileSize",file.size)
      if(file.size>1048576) // 1MB 
      {
        Swal.fire( 
          `Fail`,
          `Image size must be 1 megabytes or less `,
          `error`)
      }
     })

    let images_form = {
      files:this.files
    }
    this.service.uploadFiles(images_form).subscribe((res:any)=>{
      this.images=res['data']
    })

    
  }
 
  onRemove(event) {
  //  console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  calcTotal(){ 
    
    if((this.price.nativeElement.value==''&&this.dicount.nativeElement.value=='')||this.price.nativeElement.value==''){
      this.total=0
    }
     this.total=(this.price.nativeElement.value)-(this.price.nativeElement.value*(this.dicount.nativeElement.value/100))
  }

  submit() {
 
   delete this.form.value.compatibles
   let mod_str= this.convertArrayOfObjToStr(this.models_brands)
   let sub_str= this.convertArrayOfObjToStr(this.subCategories)
    let send_form= {
      ...this.form.value,
      product_id:this.data.id ,
      category_ids :this.categories ,
      subcategory_ids:sub_str ,
      brand_ids: this.brands,
      model_ids:mod_str,
      product_compatibles_model_ids:this.all_models,
      product_compatibles_manufacture_date_ids: this.year_str,
      image:this.images[0],
      description_images:this.d_images
    }

  console.log("form" ,  send_form)
    this.spinner.show()
    this.service.editProducts(send_form).subscribe((res:any)=>{
     this.spinner.hide()
     console.log("editProducts",res)
    if(res.status==true){
     Swal.fire(
        `Success`,
        `${res.message}`,
        `success`
      )
   
    }
    else {
      Swal.fire(
        `Fail`,
        `${res.errors[0]}`,
        `error`
      )
    }
    
    })
  }
}