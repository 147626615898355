import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';
import { EditCouponComponent } from '../edit-coupon/edit-coupon.component';

@Component({
  selector: 'app-list-coupon',
  templateUrl: './list-coupon.component.html',
  styleUrls: ['./list-coupon.component.scss']
})
export class ListCouponComponent implements OnInit {
  coupons=[]
  constructor(
    private dialog:MatDialog,
    private spinner:NgxSpinnerService,
    private service:GlobalService
    ) { }

  ngOnInit(): void {
    this.couponList()
  }
  
  couponList(){
    this.service.allCoupons().subscribe(res=>{
      //console.log("Tax_FeeList",res)
      this.coupons=res['data'] 
      this.coupons=[...this.coupons].reverse()
     // console.log("coupons",this.coupons)
    })
  }

  deleteCoupon(coupon_id){
    this.spinner.show()
    this.service.deleteCoupon(coupon_id).subscribe(res=>{
    this.spinner.hide()
    if(res['status']==true){
      Swal.fire(
        'نجاح',
        `${res['message']}`,
        'success'
        )
        this.couponList()
    }
    else {
      let error=res['errors']
      Swal.fire(
        'خطأ',
        `${error[0]}`,
        'error'
        )
    }
    
      })
    
  }

  editCoupon(coupon){
    let dialogRef = this.dialog.open(EditCouponComponent, {
      data:coupon,
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.couponList()
    });
  }

   
 
}
