import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent implements OnInit {
  form:FormGroup;
  coupon=[];
  submitted=false;
  constructor(
    private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private router:Router
    ) { }


  ngOnInit(): void {
    this.form=this.formbuilder.group({
      code:['',Validators.required],
      discount_percent:['',Validators.required],
      expiration_date:['',Validators.required],
   })
  
  }

  couponList(){
    this.service.allCoupons().subscribe(res=>{
      //console.log("Tax_FeeList",res)
      this.coupon=res['data'] 
      this.coupon=[...this.coupon].reverse()
    })
  }

  get f(){return this.form.controls}

  submit(){
    this.submitted=true;
    if(this.form.invalid){ return}
    this.spinner.show()
    this.service.addCoupon(this.form.value).subscribe(res=>{
      this.spinner.hide()
      if(res['status']==true){
        Swal.fire(
          'نجاح',
          `${res['message']}`,
          'success'
          )
          this.router.navigate(['/app/coupon/list'])
          this.couponList()
      }
      
      else {
        let error=res['errors']
        Swal.fire(
          'خطأ',
          `${error[0]}`,
          'error'
          )
      }
   
    })
  }
}
