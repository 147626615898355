import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-footer',
  templateUrl: './add-footer.component.html',
  styleUrls: ['./add-footer.component.scss']
})
 
export class AddFooterComponent implements OnInit {

  form:FormGroup;
  hobbiesArray:any;
  countries;
  submitted=false;
  d_images:any =[]
    bool:boolean = true
  constructor(
    private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private router:Router,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { }
 
  ngOnInit(): void {
    if(this.data.type!='update'){
     this.bool=false
   //  console.log(this.bool)
    }
    else {
      this.bool=true
   //   console.log(this.bool)
    }
    //console.log("update footer " , this.data)
    this.hobbiesArray = new FormArray([new FormControl('', Validators.required)]);

    this.form=this.formbuilder.group({
      phone:['',Validators.required],
      email:['',Validators.required],
      description:['',Validators.required],
      social_media_names: this.formbuilder.array([this.formbuilder.control('') ]),
      social_media_links: this.formbuilder.array([this.formbuilder.control('') ]),
   })

   
  }

  get socialNames():any {
    return this.form.get("social_media_names") as FormArray
  }
  
  addSocialMediaName() {
    this.socialNames.push(this.formbuilder.control(''))
  }
 
  get socialLinks():any {
    return this.form.get("social_media_links") as FormArray
  }
  addSocialMediaLinks() {
    this.socialLinks.push(this.formbuilder.control(''))
  }

 
  description_files:File[]=[] 
onSelectDescription_images(event) {
 
 this.description_files.push(...event.addedFiles);
 
 event.addedFiles.forEach(file=>{
  console.log("fileSize",file,file.size)
  if(file.size>1048576) // 1MB 
  {
    Swal.fire( 
      `Fail`,
      `Image size must be 1 megabytes or less `,
      `error`)
  }
 })
 if(this.bool==true){
  let images_form = {
  files:this.description_files
}
//convert file to links 
this.service.uploadFiles(images_form).subscribe((res:any)=>{
  this.d_images=res['data'] 
  this.data.images.push(this.d_images)
})

 }
 
}
 
onRemovedDescription_images(event) {
 // console.log(event);
  this.description_files.splice(this.description_files.indexOf(event), 1);
}
 
//convert uploaded file to base64
handleUpload() {
  const file =this.description_files 
  for(let i=0 ; i<file.length ; i++){
    const reader = new FileReader();
    reader.readAsDataURL(file[i]);
    reader.onload = () => {
       this.d_images.push(reader.result)
     //   console.log("reader result",i,reader.result);
    };
  }
 
}
// get f(){return this.form.controls}
  submit(){
  //  this.handleUpload()
  this.submitted=true;
 
  let f ; 

  if(this.bool==true){
   // console.log("kkk",this.data.images)
    localStorage.setItem('socail_image_update',JSON.stringify(this.data.images))
    // this.dialog.closeAll()
    // f = {
    //    social_media_logos:this.data.images,
    //  //  ...this.data.submit_data

    // }
    // console.log("update footer",f)
    // this.spinner.show()
    // this.service.editFooter(f).subscribe(res=>{
    //   console.log("RESSS",res)
    //   this.spinner.hide()
    //   if(res['status']==true){
    //     Swal.fire(
    //       'نجاح',
    //       'تم تعديل البيانات  بنجاح',
    //       'success'
    //       )
    //   }
    //   else {
    //     Swal.fire(
    //       'خطأ',
    //       'لم يتم تعديل البيانات  بنجاح',
    //       'error'
    //       )
    //   }
       
    
        
    // })
  }

  else {
    if(this.form.invalid){ return}
    f = {
      ...this.form.value ,
     // social_media_logos:this.d_images
       social_media_logos:this.description_files
    }
    console.log("DDDDD",f)
    
    this.spinner.show()
    this.service.addFooter(f).subscribe(res=>{
      this.spinner.hide()
      if(res['status']==true){
        Swal.fire(
          'نجااااح',
          'تم إضافة البيانات  بنجاح',
          'success'
          )
          this.dialog.closeAll()
      }
      else {
        Swal.fire(
          'خطأ',
          'لم يتم إضافة البيانات  بنجاح',
          'error'
          )
      }
      
      //  this.router.navigate(['/app/footer/list'])
    })
  }
 
  }
}
