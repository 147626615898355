import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddValidityComponent } from './add-validity/add-validity.component';
import { EditValidityComponent } from './edit-validity/edit-validity.component';
import { ListValidityComponent } from './list-validity/list-validity.component';
 
 

const routes: Routes = [
  {path:'add',component:AddValidityComponent,data:{title:'إضافة صلاحيات'}},
  {path:'edit',component:EditValidityComponent,data:{title:'تعديل الصلاحيات'}},
  {path:'list',component:ListValidityComponent,data:{title:'قائمة الصلاحيات'}}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ValiditiesRoutingModule { } 