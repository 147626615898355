import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CompatibleModelsComponent } from '../compatible-models/compatible-models.component';
 
 
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
compatibleModels = []
public form:FormGroup;
public submitted=false ;
public category:any ;
public categories:any=[];
public mainCategories:any=[]
public all_categories:any=[]
public subcategories_of_maincategories:any=[]
public subCategories:any=[];
public all_brands:any=[]
public models_of_brands:any=[]
public brands:any=[];
public models:any=[];
public years:any=[];
public product_compatibles_model:any=[];
public manufacture_date_id:any ;
public product_compatibles_date:any=[];
public d_images:any =[]
public images:any=[]
@ViewChild ('price')price:ElementRef;
@ViewChild ('dicount')dicount:ElementRef;
@ViewChild ('ref')ref:ElementRef;
@ViewChild ('y')y:any;
public total:any=0 ;
teamInitial :any ;
public form_compat_model=[] ; 
public form_arr=[];
public unique =[]// fake_send arr
public redundant_arr=[];
public compat=[];
public items =[]; 

  constructor(
    private formbuilder:FormBuilder,
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    private router:Router,
    public dialog: MatDialog
    ) { }

ngOnInit(): void {
  // manufacture_date_id:[null,Validators.required],
  this.form=this.formbuilder.group({
    name:[null,Validators.required],
    price:[null,Validators.required],
    discount_percent:[null],
    piece_number:[null,Validators.required],
    manufacture_place:[null,Validators.required],
    description:[null,Validators.required],
    category_ids:[null,Validators.required],
    subcategory_ids:[null,Validators.required],
    brand_ids:[null,Validators.required],
    model_ids:[null,Validators.required],
    compatibles: this.formbuilder.array([])
    })
   
  this.allCategories() ;
  this.allBrands() ;
  this.allYears();
}

get comps():any {
  return this.form.get("compatibles") as FormArray ; 
}
 
addCompat(edit = false,model = null) {
  // open CompatibleModelsComponent in popup
  const dialogRef = this.dialog.open(CompatibleModelsComponent, {
    data: {
      models_of_brands:this.models_of_brands,
      years : this.years,
      edit : edit,
      model : model
    },
    height:'500px',
    maxHeight:'700px',
    width:'500px'
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result.data && !result.data.isUpdate){
        this.compatibleModels.push(result.data)
        // remove item from models_of_brands
       // this.models_of_brands = this.models_of_brands.filter(model => model.id != result.data.model.id)
    }
    else if(result.data && result.data.isUpdate){
      this.compatibleModels = this.compatibleModels.map(u => u.model.id !== model.model.id ? u : result.data);
    }
  });
  return

// console.log("unique " , this.unique)
// this.comps.push(this.formbuilder.control(this.unique))

//   this.form.value.compatibles=this.unique;
//   this.items=this.form.value.compatibles;
//   this.items=this.items[this.items.length-1]

//   console.log("Form Add " , this.unique)
}
 
deleteCompatibles(model){
  this.compatibleModels = this.compatibleModels.filter(obj => obj.model.id != model.model.id)

  this.models_of_brands = [model.model, ...this.models_of_brands ].sort((a, b) => Number(a.id) - Number(b.id));
}
// deleteCompatibles(index,model_id) {
// // this.comps.removeAt(index) 
//   this.items=this.form.value.compatibles
  
//   console.log(this.items)
//   console.log(this.form.value.compatibles)
    
    
//   this.unique= this.unique.filter(object => {
//     return object.cat !=  model_id.value;
//   });
  

//   this.comps.removeAt(index) 
//   console.log("Form Array 2 if" , this.unique)
//   if( this.form.value.compatibles.length==0){ 
//       this.unique.length=0
//       console.log("Form Array 2 else " , this.unique)
//     }
// }

calcTotal(){ 
    
    if((this.price.nativeElement.value==''&&this.dicount.nativeElement.value=='')||this.price.nativeElement.value==''){
      this.total=0
    }
     this.total=(this.price.nativeElement.value)-(this.price.nativeElement.value*(this.dicount.nativeElement.value/100))
}

allCategories(){
    this.service.allCategories().subscribe((res:any)=>{
      this.all_categories=res['data']
      console.log("all_categories" ,this.all_categories)
    })
}

convertArrayOfstringToArrayofInt(arr){
    var num =arr.map(function(str) {
        return parseInt(str); 
    });
  return num ;
}

onChangeCategory(event){
    this.categories=event.value ;
    console.log("all selected categories" , this.categories)
    this.service.getSubcategoryByCategoryId(event.value).subscribe((res:any)=>{
    this.subcategories_of_maincategories=res['data']
    })
}

onChangeSubCategory(event){
  this.subCategories =event.value
    console.log("all selected subCategories" , this.subCategories)
}

allBrands(){
  this.service.getBrands().subscribe((res:any)=>{
    this.all_brands=res['data']
   })
}

onChangeBrands(event){
  this.brands=event.value ;
  console.log("all selected Brands", this.brands)
   this.service.getModelsByBrandId(event.value).subscribe((res:any)=>{
   this.models_of_brands=res['data']
   console.log("asdfs",this.models_of_brands)
  })
}
 
onChangeModels(event){
  this.models =event.value
    console.log("all selected models" , this.models)
}
 
allYears(){
this.service.allYears().subscribe((res:any)=>{
   this.years=res['data']
  //  console.log("years" ,this.years )
})
}
 
onChangeManufactureDate(event){
  this.manufacture_date_id= event.value
  console.log("manufature year " ,this.manufacture_date_id)
}

// onChangeCompatibleProductModel(event){
//   this.teamInitial = '';
//   //this.teamInitial2=event.value
//   console.clear()
//   console.log(event.value)

//    this.product_compatibles_model= event.value
//    this.form_compat_model.push(event.value)
//    this.form_compat_model=this.form_compat_model.sort()
//    console.log("MMMMMMMMM",this.form_compat_model)
   
// }


onChangeCompatibleProductModel(event , index){
  this.teamInitial = '';
  //this.teamInitial2=event.value
  //console.clear()
  console.log(event.value)

   this.product_compatibles_model= event.value
   this.form_compat_model[index]= event.value
  //  this.form_compat_model=this.form_compat_model.sort()
   console.log("MMMMMMMMM",this.form_compat_model)
   
}

onChangeCompatibleYear(event , index) {
   console.log("yyyyy",index ) 
  this.compat.length=0;
  this.product_compatibles_date=event.value
   
 // console.clear()
  console.log("mmmmm",this.product_compatibles_model)
  console.log("yyyyyy",this.product_compatibles_date)
 
  if(this.product_compatibles_date.length !=0 && this.product_compatibles_model.length!=0){
 
      for(let j=0 ; j<this.product_compatibles_date.length ; j++){
         this.compat.push({'cat':this.product_compatibles_model, 'sub':this.product_compatibles_date[j]})
         this.redundant_arr.push(this.compat[j])
        
         }
     }
    
      this.unique = this.redundant_arr.filter((obj, index) => {
        return index === this.redundant_arr.findIndex(o => obj.cat === o.cat && obj.sub === o.sub);
      });

 
  console.log("GG Year",  this.unique)
  this.form.value.compatibles=this.unique
   console.log("GG Arr Year", this.form.value.compatibles)
  
  
}

// onChangeCompatibleYear(event) {
//   this.compat.length=0;
//   this.product_compatibles_date=event.value
   
//  // console.clear()
//   console.log("mmmmm",this.product_compatibles_model)
//   console.log("yyyyyy",this.product_compatibles_date)
 
//   if(this.product_compatibles_date.length !=0 && this.product_compatibles_model.length!=0){
 
//       for(let j=0 ; j<this.product_compatibles_date.length ; j++){
//          this.compat.push({'cat':this.product_compatibles_model, 'sub':this.product_compatibles_date[j]})
//          this.redundant_arr.push(this.compat[j])
        
//          }
//      }
    
//       this.unique = this.redundant_arr.filter((obj, index) => {
//         return index === this.redundant_arr.findIndex(o => obj.cat === o.cat && obj.sub === o.sub);
//       });

 
//   console.log("GG Year",  this.unique)
//   this.form.value.compatibles=this.unique
//    console.log("GG Arr Year", this.form.value.compatibles)
  
  
// }

description_files:File[] = [];
onSelectDescription_images(event) {
 console.log(event.addedFiles);
 this.description_files.push(...event.addedFiles);
 event.addedFiles.forEach(file=>{
  console.log("fileSize",file.size)
  if(file.size>1048576) // 1MB 
  {
    Swal.fire( 
      `Fail`,
      `Image size must be 1 megabytes or less `,
      `error`)
  }
 })
 let images_form = {
  files:this.description_files
}

this.service.uploadFiles(images_form).subscribe((res:any)=>{
  this.d_images=res['data']
  
})
}

onRemovedDescription_images(event) {
  console.log(event);
  this.description_files.splice(this.description_files.indexOf(event), 1);
}

files:File[] = [];
onSelect(event) {
   
 console.log(event.addedFiles);
 this.files=[]
 this.files.push(...event.addedFiles);
 
 event.addedFiles.forEach(file=>{
  console.log("fileSize",file,file.size)
  if(file.size>1048576) // 1MB 
  {
    Swal.fire( 
      `Fail`,
      `Image size must be 1 megabytes or less `,
      `error`)
  }
 })

 let images_form = {
  files:this.files
}
 this.service.uploadFiles(images_form).subscribe((res:any)=>{
  this.images=res['data']
 })
 
}

onRemove(event) {
  console.log(event);
  this.files.splice(this.files.indexOf(event), 1);
}
 
convertArrayOfObjToStr(arrOfobjs){
  var subv=[]
  var newArray = arrOfobjs.reduce(function(acc, curr) {
    var findIfNameExist = acc.findIndex(function(item) {
       return item.cat === curr.cat;
     })
     if (findIfNameExist === -1) {
       let obj = {
         'cat': curr.cat,
         "value": [curr.sub]
       }
       acc.push(obj)
     } 
     else {
     acc[findIfNameExist].value.push(curr.sub)
     }
     return acc;
    }, []);
 
 
   let sortedProducts = newArray.sort((s1, s2) => {
     return s1.cat - s2.cat;
   });
  
    for(var i=0 ; i<newArray.length ; i++){
      subv.push(newArray[i].value)
   }
   console.log("subv",subv)
   let finalStr = subv.map(a => a.join(",")).join('|');
 
  return finalStr;
}

get f() {return this.form.controls}

submit(){ 
this.submitted=true
console.log("FFFFFFF" , this.form.value)
 
let arr = this.form.value.subcategory_ids.map(object => object.cat);
arr=this.convertArrayOfstringToArrayofInt(arr)
 
 
let difference = this.form.value.category_ids.filter(x => arr.includes(x));
 
let arr2 = this.form.value.model_ids.map(object => object.cat);
arr2=this.convertArrayOfstringToArrayofInt(arr2)
 
let difference2 = this.form.value.brand_ids.filter(x => arr2.includes(x));

 
let str_sub = this.convertArrayOfObjToStr(this.form.value.subcategory_ids)
 

this.form.value.model_ids.sort((a,b) => (a.cat > b.cat) ? 1 : ((b.cat > a.cat) ? -1 : 0)) 
 
let str_mod =this.convertArrayOfObjToStr(this.form.value.model_ids)
let compat_years = this.convertArrayOfObjToStr(this.unique)
console.log("compat_years compat_years compat_years compat_years ",compat_years )
    let newData = {};
    Object.entries(this.form.value)
      .filter(([, value]) => value != null)
      .forEach(([key, value]) => (newData[key] = value));
     
     let f={
        ...newData,
        image :this.images,
        categories: difference,
        subcategories:str_sub ,
        brands:difference2,
        models:str_mod ,
        product_compatibles: this.form_compat_model,
        product_compatibles_manufacture_date_ids:compat_years,
        desc_images:this.d_images,
        compatibleModels : this.compatibleModels 
      }
   
      console.log('the sended form is ',f)
      this.spinner.show()
      this.service.addProducts(f).subscribe((res:any)=>{
      this.spinner.hide()
        console.log("addProducts",res)
        if(res.status==true){
        Swal.fire(
            `Success`,
            `${res.message}`,
            `success`
          )
          // this.form.reset();
          // this.files=[] ;
          // this.description_files =[];
        }
        else {
          Swal.fire(
            `Fail`,
            `${res.errors[0]}`,
            `error`
          )
        }
      },
      (err:any)=>{
        this.spinner.hide()
        Swal.fire(
          `Fail`,
          `تأكد من إضافة جميع البيانات بشكل صحيح `,
          `success`
        )
      })
}

}
