import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { AddShippingFeeComponent } from '../add-shipping-fee/add-shipping-fee.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-list-shipping-fee',
  templateUrl: './list-shipping-fee.component.html',
  styleUrls: ['./list-shipping-fee.component.scss']
})
export class ListShippingFeeComponent implements OnInit {
  form:FormGroup;
  info;
  submitted=false;
  private dialog:MatDialog;
  constructor(
    private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private router:Router
    ) { }


  ngOnInit(): void {
    this.form=this.formbuilder.group({
      tax:['',Validators.required],
      shipping_fee:['',Validators.required],
   })
  this.Tax_FeeList()
  }

  Tax_FeeList(){
    this.service.getTaxAndShippingFee().subscribe(res=>{
     // console.log("Tax_FeeList",res)
      this.info=res['data'] 
      this.form.patchValue({
        tax:this.info.tax,
        shipping_fee:this.info.shipping_fee
      });
    })
  }

 
  // editShippingFee(tax,fee) {
  //   let dialogRef = this.dialog.open(AddShippingFeeComponent, {
  //     data:{shipping_fee:fee ,tax:tax},
  //     height: '600px',
  //     width: '600px',
  //   });
  //   dialogRef.afterClosed().subscribe(res => {
  //     console.log(res);
  //     this.Tax_FeeList()
  //   })
  // }
  
}
