import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-special-answer',
  templateUrl: './special-answer.component.html',
  styleUrls: ['./special-answer.component.scss']
})
export class SpecialAnswerComponent implements OnInit {
  answers=[]
  constructor(private spinner:NgxSpinnerService,  private formbuilder:FormBuilder, private service:GlobalService , @Inject(MAT_DIALOG_DATA)public data:any,private dialog:MatDialog) { }


  ngOnInit(): void {
    this.answers=this.data
    console.log("answers",this.data)
  }

}
