import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersRoutingModule } from './orders-routing.module';
import { ListComponent } from './list/list.component';
import { OrderDetailsComponent } from './list/order-details/order-details.component';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SpecialOrdersComponent } from './special-orders/special-orders.component';
import { AnswerProductsComponent } from './answer-products/answer-products.component';
import { MatSelectModule} from '@angular/material/select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SpecialAnswerComponent } from './special-answer/special-answer.component';
@NgModule({
  declarations: [ListComponent, OrderDetailsComponent, SpecialOrdersComponent, AnswerProductsComponent ,AnswerProductsComponent ,SpecialAnswerComponent],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    MatPaginatorModule,
    NgMultiSelectDropDownModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule
  ],
  providers:[AnswerProductsComponent]
})
export class OrdersModule { }
