import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private http:HttpClient , private router:Router) { }

 //////////////////////////////// Upload Files ///////////////////////////////

 uploadFiles(f) {
  const formData:FormData = new FormData()
  //console.log(f)
  for (let i = 0; i < f.files.length; i++) {
    formData.append('files['+i+']',f.files[i])
  }

  return this.http.post(`${environment.endpoint}/files/upload`,formData)
}
 ////////////////////////////////Category/////////////////////////////////////

  allCategories() {
    return this.http.get(`${environment.endpoint}/categories/all`)
  }

  addCategory(f) {
      const formData:FormData = new FormData()
   //   console.log(f)
      formData.append('name',f.name)
      formData.append('name_en',f.name_en)
      formData.append('image',f.image)
      return this.http.post(`${environment.endpoint}/admin/category/create`,formData)
  }

  editCategory(f) {
    //  console.log('ss',f)
      const formData:FormData = new FormData()
      formData.append('name',f.name)
      formData.append('name_en',f.name_en)
      formData.append('category_id',f.category_id) 
      if(f.image!=null){
        formData.append('image',f.image)
      }
      return this.http.post(`${environment.endpoint}/admin/category/update`,formData)
  }

  deleteCategory(category_id){
      //console.log(category_id)
      // const forms:FormData = new FormData();
      return this.http.delete(`${environment.endpoint}/admin/category/delete?category_id=${category_id}`)
  }

  //category details
  getCategoryById(category_id){
      return this.http.get(`${environment.endpoint}/category/show?category_id=${category_id}`)
   }

  //////////////////////////// SubCategory /////////////////////////////////
  
  addSubCategory(f){
      const formData:FormData = new FormData()
      formData.append('name',f.name)
      formData.append('category_id',f.category_id)
      return this.http.post(`${environment.endpoint}/admin/subcategory/create`,formData)
  }

  editSubCategory(f){
      const formData:FormData = new FormData()
      formData.append('name',f.name)
      formData.append('sub_category_id',f.sub_category_id)
     
      return this.http.post(`${environment.endpoint}/admin/subcategory/update`,f)
  }

  deleteSubCategory(sub_category_id){
      return this.http.delete(`${environment.endpoint}/admin/subcategory/delete?sub_category_id=${sub_category_id}`)
  }

  getSubcategoryByCategoryId(category_ids:any){
     const formData:FormData = new FormData()
      for (let i = 0; i < category_ids.length; i++) {
        formData.append('category_ids['+i+']',category_ids[i])
      }
      return this.http.post(`${environment.endpoint}/subcategories/all`,formData)
      // return this.http.get(`${environment.endpoint}/subcategories/all?category_id=${category_ids}`)
  }
  /////////////////////////Brands//////////////////////////////////
  getBrands(){
    return this.http.get(`${environment.endpoint}/brands/all`)
  }

  addBrand(f){
    const formdata:FormData = new FormData();
    formdata.append('logo_image',f.logo_image)
    formdata.append('name',f.name)
    formdata.append('name_en',f.name_en)
    formdata.append('description',f.description)
   
    return this.http.post(`${environment.endpoint}/admin/brand/create`,formdata)
  }

  editBrand(f){
    // console.log("####",f.logo_image)
      const formData:FormData = new FormData();
       formData.append('name',f.name);
       formData.append('name_en',f.name_en);
       formData.append('description',f.description);
       formData.append('brand_id',f.brand_id);
        if(f.logo_image!=null){
         formData.append('logo_image',f.logo_image);
        }
     
    return this.http.post(`${environment.endpoint}/admin/brand/update`,formData)
  }

  deleteBrand(brand_id){
   return this.http.delete(`${environment.endpoint}/admin/brand/delete?brand_id=${brand_id}`)
  }

  /////////////////////////Models//////////////////////////////////

  getModelsByBrandId(brand_ids){
    // return this.http.get(`${environment.endpoint}/models/all?brand_id=${brand_id}`)
   
    const formData:FormData = new FormData()
    for (let i = 0; i < brand_ids.length; i++) {
      formData.append('brand_ids['+i+']',brand_ids[i])
    }
    return this.http.post(`${environment.endpoint}/models/all`,formData)
 
  }

  addModels(f){
    const formdata:FormData = new FormData();
    formdata.append('brand_id',f.brand_id);
    formdata.append('name',f.name)
    formdata.append('name_en',f.name_en);
    formdata.append('description',f.description)
    return this.http.post(`${environment.endpoint}/admin/model/create`,formdata)
  }

  editModels(f){
      const formData:FormData = new FormData();
      formData.append('model_id',f.model_id);
      formData.append('name',f.name);
      formData.append('name_en',f.name_en);
      formData.append('description',f.description)
    return this.http.post(`${environment.endpoint}/admin/model/update`,formData)
  }

  deleteModels(model_id){
   return this.http.delete(`${environment.endpoint}/admin/model/delete?model_id=${model_id}`)
  }
  
//////////////////////////// Manufacture Date /////////////////////////////

allYears(){
  return this.http.get(`${environment.endpoint}/manufacture-dates/all`);
}

addYear(f){
  const formData:FormData = new FormData();
  formData.append('date',f.date);
  return this.http.post(`${environment.endpoint}/admin/manufacture-date/create`, formData);
}

editYear(f){
  const formData:FormData = new FormData();
  formData.append('date',f.date);
  formData.append('manufacture_date_id',f.manufacture_date_id);
  return this.http.post(`${environment.endpoint}/admin/manufacture-date/update`, formData);
}

deleteYear(manufacture_date_id){
  return this.http.delete(`${environment.endpoint}/admin/manufacture-date/delete?manufacture_date_id=${manufacture_date_id}`);
}
////////////////////////////////Products ///////////////////////////////
  
  filterProduct(form) {
   // console.log("fff",form )
    const formData:FormData = new FormData()
      let notNullValue;
    // let i=0;
     for(let prop in form) {
      
       if( (form[prop].length!=0) && (prop!='page') && (prop!='piece_number') ){
        // i++;
         notNullValue = {[prop]: form[prop]}
         //console.log(notNullValue)
       //  console.log("prop", prop ,...form[prop] )
          formData.append(prop +'['+0+']',form[prop])
       }
     }
     formData.append('page',form.page)
     if(form.piece_number ){
      formData.append('piece_number', form.piece_number )
     }
 
      return this.http.post(`${environment.endpoint}/products/filter`,formData)
  }

  addProducts(f){
    // console.clear()
    console.log(f)
    const formData:FormData = new FormData()
   // console.log("sf",f.subcategories)
   // console.log("mf",f.models)
    // debugger;
  //  console.log(f)
    formData.append('image',f.image)
    formData.append('name',f.name)
    formData.append('description',f.description)
    formData.append('price',f.price)
    if(f.discount_percent){
      formData.append('discount_percent',f.discount_percent)
    }
   
    formData.append('piece_number',f.piece_number)
    formData.append('manufacture_place',f.manufacture_place)
    // formData.append('manufacture_date_id',f.manufacture_date_id)

    formData.append('subcategory_ids',f.subcategories)
    formData.append('model_ids',f.models)
    
    // description_images
    for (let i = 0; i < f.desc_images.length; i++) {
      formData.append('description_images['+i+']',f.desc_images[i])
      }

    // categories
    for (let i = 0; i < f.categories.length; i++) {
        formData.append('category_ids['+i+']',f.categories[i])
      }
 
    //brands
    for (let i = 0; i < f.brands.length; i++) {
      formData.append('brand_ids['+i+']',f.brands[i])
    }
 
    //product_compatibles_model_ids
  //   for (let i = 0; i < f.product_compatibles.length; i++) {
  //   formData.append('product_compatibles_model_ids['+i+']',f.product_compatibles[i])
  // }

  //  formData.append('product_compatibles_manufacture_date_ids',f.product_compatibles_manufacture_date_ids)
   

      
     // console.clear()
      var manufacture_date_ids = ''
      for (let index = 0; index < f.compatibleModels.length; index++) {
        formData.append('product_compatibles_model_ids['+index+']',f.compatibleModels[index]['model']['id'])
        let years = f.compatibleModels[index];
        var lastMainItem =  (true ? index ===  f.compatibleModels.length -1 : false)

        for (let dateIndex = 0; dateIndex < years.dates.length; dateIndex++) {
          let lastItem =  (true ? dateIndex ===  years.dates.length -1 : false)
          manufacture_date_ids = manufacture_date_ids + years.dates[dateIndex]['id'] + (lastItem ? (lastMainItem ? '' : '|') : ',')
        } 
      }
      formData.append('product_compatibles_manufacture_date_ids',manufacture_date_ids)
    return this.http.post(`${environment.endpoint}/admin/product/create`,formData)
  } 

  editProducts(f){
    const formData:FormData = new FormData()
    for (const [key, value] of Object.entries(f)) {
      if(value!=""&& !Array.isArray(value) &&key!='image') {
        formData.append(key,`${value}`)
      }
      else if(Array.isArray(value) && value.length!=0) {
        for(let i=0 ; i<value.length ; i++)
        if(value[i]!='') {
          formData.append( key+'['+i+']'  , value[i])
        }
      }
    }
    if(f.image!=null){
      formData.append('image', f.image)
    }
 
    // console.log("sf",f.subcategories)
    // console.log("mf",f.models)
  
    // console.log(f)
    // formData.append('image',f.image)
    // formData.append('product_id',f.product_id)
    // formData.append('name',f.name)
    // formData.append('description',f.description)
    // formData.append('price',f.price)
    // if(f.discount_percent){
    //   formData.append('discount_percent',f.discount_percent)
    // }
   
    // formData.append('piece_number',f.piece_number)
    // formData.append('manufacture_place',f.manufacture_place)
 

    // formData.append('subcategory_ids',f.subcategories)
    // formData.append('model_ids',f.models)
    
  
    // if(f.desc_images.length!=0){
    //   for (let i = 0; i < f.desc_images.length; i++) {
    //     formData.append('description_images['+i+']',f.desc_images[i])
    //     }
    // }
    
     
    // for (let i = 0; i < f.categories.length; i++) {
    //     formData.append('category_ids['+i+']',f.categories[i])
    //   }
 
     
    // for (let i = 0; i < f.brands.length; i++) {
    //   formData.append('brand_ids['+i+']',f.brands[i])
    // }
 
     
    //    for (let i = 0; i < f.product_compatibles.length; i++) {
    //     formData.append('product_compatibles_model_ids['+i+']',f.product_compatibles[i])
    //   }
     
    
    //   formData.append('product_compatibles_manufacture_date_ids',f.product_compatibles_manufacture_date_ids)
      
      return this.http.post(`${environment.endpoint}/admin/product/update`,formData)
  }
  
  deleteProducts(product_id){
    return this.http.delete(`${environment.endpoint}/admin/product/delete?product_id=${product_id}`);
  }

 getProductById(product_id){
  return this.http.get(`${environment.endpoint}/product/show?product_id=${product_id}`) ;
 }
 
  uploadExcelFile(file:any){
    const formData:FormData = new FormData();
    formData?.append("file" ,file)
    return this.http.post(`${environment.endpoint}/admin/products/import-excel`,formData)
  }

   //////////////////////////////// Orders //////////////////////////////
  orders(status_id,page){
    return this.http.get(`${environment.endpoint}/admin/orders/show?status_id=${status_id}&page=${page}`) ;
 }

 manageOrders(f) {
  const formData:FormData = new FormData();
    formData?.append("order_id" ,f.order_id)
    formData?.append("status_id" ,f.status_id)
    return this.http.post(`${environment.endpoint}/admin/order/manage`,formData)
 }
 //////////////////////////////////Cities //////////////////////////////////
 allCities(){
  return this.http.get(`${environment.endpoint}/cities/all`)
}

 addCity(f){
  return this.http.post(`${environment.endpoint}/admin/city/create`,f)
}

editCity(f){
  return this.http.post(`${environment.endpoint}/admin/city/update`,f)
}

deleteCity(city_id){
  return this.http.delete(`${environment.endpoint}/admin/city/delete?city_id=${city_id}`)
}
   ////////////////////////////////Footer///////////////////////////////
getFooter(){
   return this.http.get(`${environment.endpoint}/footer/show`)
  }   
   
addFooter(f){

   const formData:FormData = new FormData();
   
  for (const [key, value] of Object.entries(f)) {
    if(value!=null && Array.isArray(value)==false) {
      formData.append(key,`${value}`)
     }}
    
    if(f.social_media_names.length!=0){
      for (let i = 0; i < f.social_media_names.length; i++) {
       formData.append('social_media_names['+i+']',f.social_media_names[i])
       }
    }

    if(f.social_media_links.length!=0){
      for (let i = 0; i < f.social_media_links.length; i++) {
         formData.append('social_media_links['+i+']',f.social_media_links[i])
       }
    }
 
  if(f.social_media_logos.length!=0){
    for (let i = 0; i < f.social_media_logos.length; i++) {
       formData.append('social_media_logos['+i+']',f.social_media_logos[i])
      }
  }
 
    return this.http.post(`${environment.endpoint}/admin/footer/create`,formData)
  }

editFooter(f){

  const formData:FormData = new FormData();
  for (const [key, value] of Object.entries(f)) {
   if(value!=null && Array.isArray(value)==false) {
      formData.append(key,`${value}`)
    }
  }

    if(f.social_media_names.length!=0){
      for (let i = 0; i < f.social_media_names.length; i++) {
        formData.append('social_media_names['+i+']',f.social_media_names[i])
        }
    }

    if(f.social_media_links.length!=0){
      for (let i = 0; i < f.social_media_links.length; i++) {
        formData.append('social_media_links['+i+']',f.social_media_links[i])
        }
    }
 
  if(f.social_media_logos.length!=0){
    for (let i = 0; i < f.social_media_logos.length; i++) {
      formData.append('social_media_logos['+i+']',f.social_media_logos[i])
      }
  }
 
    return this.http.post(`${environment.endpoint}/admin/footer/update`,formData)
  }
  
 ////////////////////////////////// banners //////////////////////////////////// 

    allBanners(){
      return this.http.get(`${environment.endpoint}/banner/show`)
    }
    
    addBanner(f){
    //  console.log("Edit Banners" ,f)
      const formData:FormData = new FormData()
      if(f.image.length!=0){
      for (let i = 0; i < f.image.length; i++) {
         formData.append('images['+i+']',f.image[i])
        }
      }
      return this.http.post(`${environment.endpoint}/admin/banner/create`,formData)
    }

    //edit =replace 
  editBanner(f){
   //   console.log("Edit Banner" ,f)
      const formData:FormData = new FormData()
      if(f.image.length!=0){
      for (let i = 0; i < f.image.length; i++) {
         formData.append('images['+i+']',f.image[i])
       }
    }
      return this.http.post(`${environment.endpoint}/admin/banner/update`,formData)
  }
 ////////////////////////////////// Home Reviews /////////////////////////////// 
 allHomeReviews(){
  return this.http.get(`${environment.endpoint}/home/reviews`)
 }

 addHomeReviews(f){
  //console.log("addHomeReviews",f)
  const formData:FormData = new FormData();
   formData.append('name',f.name)
   formData.append('country',f.country)
   formData.append('review',f.review)
   formData.append('rating',f.rating)
   formData.append('image',f.image)
     return this.http.post(`${environment.endpoint}/admin/home/review/create`,formData)
 }

 editHomeReviews(f){
 // console.log("addHomeReviews",f)
  const formData:FormData = new FormData();
   
  formData.append('home_review_id',f.home_review_id)
   formData.append('name',f.name)
   formData.append('country',f.country)
   formData.append('review',f.review)
   formData.append('rating',f.rating)
   if(f.image!=undefined){
    formData.append('image',f.image)
   }
 
     return this.http.post(`${environment.endpoint}/admin/home/review/update`,formData)
 }

 deleteHomeReviews(home_review_id){
  return this.http.delete(`${environment.endpoint}/admin/home/review/delete?home_review_id=${home_review_id}`)  
 }
 ////////////////////////////////// admin logout /////////////////////////// 
 adminLogout(){
 
 return this.http.get(`${environment.endpoint}/admin/logout`)
}
 ////////////////////////////////// Coupon //////////////////////////////////
 allCoupons(){
  return this.http.get(`${environment.endpoint}/coupons/all`)
 }

 addCoupon(f){
 // console.log("addHomeReviews",f)
  const formData:FormData = new FormData();
   formData.append('code',f.code)
   formData.append('discount_percent',f.discount_percent)
   formData.append('expiration_date',f.expiration_date)
    return this.http.post(`${environment.endpoint}/admin/coupon/create`,formData)
 }

 editCoupon(f){
 // console.log("addHomeReviews",f)
  const formData:FormData = new FormData();
   formData.append('coupon_id',f.coupon_id)
   formData.append('code',f.code)
   formData.append('discount_percent',f.discount_percent)
   formData.append('expiration_date',f.expiration_date)
     return this.http.post(`${environment.endpoint}/admin/coupon/update`,formData)
 }

 getCouponDetails(coupon_code){
  return this.http.get(`${environment.endpoint}/coupon/show?coupon_code=${coupon_code}`)
 }

 deleteCoupon(coupon_id){
  return this.http.delete(`${environment.endpoint}/admin/coupon/delete?coupon_id=${coupon_id}`)
 }
  ///////////////////////////// Tax and Shipping Fee  ///////////////////// 
  getTaxAndShippingFee(){
    return this.http.get(`${environment.endpoint}/get/options`)
   }

   SetTaxAndShippingFee(tax,shipping_fee){
    return this.http.get(`${environment.endpoint}/admin/set/options?tax=${tax}&shipping_fee=${shipping_fee}`)
   }
 ////////////////////////////////// SCAR //////////////////////////////////
  allSubCategories(){
      return this.http.get(`${environment.endpoint}/secondary-specialists`)
  }
 
 allProducts(){
      return this.http.get(`${environment.endpoint}/products`)
  }
    //All Users
  allUsers(active){
    return this.http.get(`${environment.endpoint}/users?type=1&active=${active}`)
  }
 
  changeUserStatus(user_id,active_id){
    // const formData:FormData = new FormData()
    // formData.append('user_id',user_id)
    // formData.append('active_id',active_id)
  //  console.log('hello fro change',user_id,active_id)
    // ${environment.endpoint}/users?type=2&active=${active}
    return this.http.get(`${environment.endpoint}/users/active?user_id=${user_id}&active=${active_id}`)
  }
  //////////////////////////Taxes /////////////////////////////////
  editTaxes(f){
    return this.http.post(`${environment.endpoint}/settings/update`,f)
  }

  AllTaxes(){
    return this.http.get(`${environment.endpoint}/settings`)
  }

  // Exposed Questions
  questionsList() {
    return this.http.get(`${environment.endpoint}/frequent-questions`);
  }

  addQuestion(questionData) {
    return this.http.post(`${environment.endpoint}/frequent-questions/create`, questionData);
  }

  updateQuestion(questionObj) {
    return this.http.post(`${environment.endpoint}/frequent-questions/update`, questionObj);
  }

  deleteQuestion(question_id) {
    return this.http.get(`${environment.endpoint}/frequent-questions/delete?question_id=${question_id}`);
  }

  // Add Model
  addModel(modelData) {
    return this.http.post(`${environment.endpoint}/types/create`, modelData);
  }

  allModels() {
    return this.http.get(`${environment.endpoint}/types`);
  }

  updateModel(updatedModel) {
    return this.http.post(`${environment.endpoint}/types/update`, updatedModel);
  }

  deleteModel(model_id) {
    return this.http.get(`${environment.endpoint}/types/delete?type_id=${model_id}`);
  }

  //clients 
  getClients(){
    return this.http.get(`${environment.endpoint}/clients`);

  }
  //maintainers
  getMaintainers(active_status){
    return this.http.get(`${environment.endpoint}/maintainers?active=${active_status}`);

  }

  changeMaintainerStatus(id,active){

    return this.http.get(`${environment.endpoint}/maintainers/active?maintainer_id=${id}&active=${active}`);

  }
  //deliveries 
  getDeliveries(status){
   
    return this.http.get(`${environment.endpoint}/deliveries?active=${status}`);

  }

  changeDeliveriesStatus(id,active){

    return this.http.get(`${environment.endpoint}/deliveries/active?delivery_user_id=${id}&active=${active}`);

  }

  addSubCategoryValue(f){
    return this.http.post(`${environment.endpoint}/secondary-specialists-values/create`, f);

  }

  deletesubcatvalue(id){
    return this.http.get(`${environment.endpoint}/secondary-specialists-values/delete?secondary_specialist_value_id=${id}`);

  }
//////////////////////////Band ///////////////////////////
addBand(f){
  return this.http.post(`${environment.endpoint}/secondary-specialists-values/create`, f);
}
///////////////////////////Color/////////////////////////////
addColor(f){
  return this.http.post(`${environment.endpoint}/colors/create`, f);
}
allColors(){
  return this.http.get(`${environment.endpoint}/colors`);  
}
deleteColor(id){
 return this.http.get(`${environment.endpoint}/colors/delete?color_id=${id}`);
}
////////////////////////////////Terms Of Conditions //////////////////////////////////////
addTermsFiles(f){
  return this.http.post(`${environment.endpoint}/settings/update-files`, f);
}
//////////////////////////////////Prices ///////////////////////////
allPrices(){
  return this.http.get(`${environment.endpoint}/prices`); 
}

editPrices(f){
  return this.http.post(`${environment.endpoint}/prices/edit`, f);
}
/////////////////////////////////////////////////////////////////
getMainSpecialistByBrandId(ids){
  return this.http.get(`${environment.endpoint}/main-specialists?brands[0]=${ids}`);
}
/////////////////////////////////////////////////////////
 
getAllFiles(){
   return this.http.get(`${environment.endpoint}/settings`); 
}

/////////////////////////////////////////////////////////
  //All orders
  allOrders(type){
    return this.http.get(`${environment.endpoint}/orders?status_id=${type}`)
   }

  allInstantOrders(page){
   return this.http.get(`${environment.endpoint}/admin/instant-orders/all?page=${page}`)
   }

  addAnswerToInstantOrder(f:any){
    console.log("add answer",f)
   const formData:FormData = new FormData();
   formData.append('instant_order_id',f.instant_order_id)
   for (let i = 0; i < f.products_ids.length; i++) {
   formData.append('products_ids['+i+']',f.products_ids[i])
    }
    return this.http.post(`${environment.endpoint}/admin/instant-order/answer`,formData)
  }

  filterOrders(f:any){
    const formData:FormData = new FormData();
    for (const [key, value] of Object.entries(f)) {
      if(value!=null) {
         formData.append(key,`${value}`)
       }
     }
     return this.http.post(`${environment.endpoint}/admin/orders/count/filter-by-date`,formData)
  }

  filterBrands(f:any) {
    const formData:FormData = new FormData();
    for (const [key, value] of Object.entries(f)) {
      if(value!=null) {
         formData.append(key,`${value}`)
       }
     }
   return this.http.post(`${environment.endpoint}/admin/orders/count/filter-by-brand`,formData)
   }
//////////////////////
//permissions types

showAllPermissions(){
  return this.http.get(`${environment.endpoint}/admin/permissions/all`); 
}

addAllPermissions(form:any){
  const formData:FormData = new FormData()
  for (let i = 0; i < form.names.length; i++) {
    formData.append('names['+i+']',form.names[i])
  }
  for (let i = 0; i < form.uids.length; i++) {
    formData.append('uids['+i+']',form.uids[i])
  }
 return this.http.post(`${environment.endpoint}/admin/permission/create`, formData);
}

updateAllPermissions(form){
  const formData:FormData = new FormData()
  formData.append('id',form.id)
  return this.http.post(`${environment.endpoint}/admin/permission/update`, formData);
}

deletePermisions(permission_id:any){
  return this.http.delete(`${environment.endpoint}/admin/permission/delete?permission_id=${permission_id}`)
}

////////////////////////////////////////
// users
showAllSubdmainPermissions(){
  return this.http.get(`${environment.endpoint}/admin/sub-admins/all`); 
}

addAllSubdmainPermissions(form:any){
  const formData:FormData = new FormData()
  // formData.append('name',form.name)
  // formData.append('email',form.email)
  // formData.append('password',form.password)
  for (const [key, value] of Object.entries(form)) {
    if(value!=null && !Array.isArray(value)) {
      formData.append(key,`${value}`)
    }
  }
  for (let i = 0; i < form.permissions_ids.length; i++) {
    formData.append('permissions_ids['+i+']',form.permissions_ids[i])
  }
 return this.http.post(`${environment.endpoint}/admin/sub-admin/create`, formData);
}

editAllSubdmainPermissions(form:any){
  const formData:FormData = new FormData()
  // formData.append('subadmin_id',form.subadmin_id)
  // formData.append('name',form.name)
  // formData.append('email',form.email)
  // formData.append('password',form.password)
    for (const [key, value] of Object.entries(form)) {
    if(value!=null && !Array.isArray(value)) {
      formData.append(key,`${value}`)
    }
  }
  for (let i = 0; i < form.permissions_ids.length; i++) {
    formData.append('permissions_ids['+i+']',form.permissions_ids[i])
  }



  /*
   for (const [key, value] of Object.entries(form)) {
       if(Array.isArray(value) && value.length!=0) {
        for(let i=0 ; i<value.length ; i++)
          if(value[i]!='') {
             formData.append( key+'['+i+']' , value[i])
          }
      }
    }*/
  return this.http.post(`${environment.endpoint}/admin/sub-admin/update`, formData);
}

deleteAllSubdmainPermissions(subadmin_id:any){
  return this.http.delete(`${environment.endpoint}/admin/sub-admin/delete?subadmin_id=${subadmin_id}`)
}
//////////////////////
// editMaintain(f){}
// addMaintain(f){}
// getComp(type){}
}

 //models
  //   for (let index = 0; index < f.models.length; index++) {
  //     // const element = array[index];
  //  //   console.log(f.models[index])
  //   formData.append('model_ids['+f.models[index][0]+']['+index+']', f.models[index][1])
      
  //   }

  
    // for(var i = 0; i < f.models.length; i++) {
    //   var cube = f.models[i];
    //   for(var j = 0; j < cube.length; j++) {
    //     formData.append('model_ids['+i+']['+j+']',cube[j])
    //   }
    // }
     
  // }

  

     
      ////////////////////////////////////////////////////////////////////////////////////////
   
    // subcategories
    // for (let index = 0; index < f.subcategories.length; index++) {
    // formData.append('subcategory_ids['+f.subcategorie=s[index][0]+']['+index+']', f.subcategories[index][1])  
    // }

    // for(var i = 0; i < f.subcategories.length; i++) {
    //   var cube = f.subcategories[i];
    //   for(var j = 0; j < cube.length; j++) {
    //     formData.append('subcategory_ids['+i+']['+j+']',cube[j])
    //   }
    // }
    // var array1 = [false, 0, "Juice", -1],
    // array2 = ["35", "17", "21", "99"],
    // result = [],
    // s ;
   
    // for(var i=0 ; i<f.subcategories.length; i++ ) {
      
    // }

// for ( var i = 0; i < f.subcategories.length; i++ ) {
//   if( f.subcategories[i].cat== f.subcategories[i+1]?.cat && f.subcategories.length!=0){
//     result.push( [ f.subcategories[i].sub ,1] );
//   }
//   else if( f.subcategories[i].cat!= f.subcategories[i+1]?.cat && i==0){
//     result.push( [ f.subcategories[i].sub ,1] );
//   }

// }
 
// for(var i = 0; i < result.length; i++) {
//   var cube = result[i];
//   for(var j = 0; j < cube.length; j++) {
//     s= cube[j]
//     console.log("cube[" + (i) + "][" + (j) + "] = " , s);

//   }

// }
 
  //   for(var i = 0; i < f.categories.length; i++) {
  
  //     for(var j = 0; j < f.subcategories.length; j++) {
  //       if( f.subcategories[i]?.cat == f.subcategories[i+1]?.cat ){
  //       formData.append('subcategory_ids['+i+']['+j+']',f.subcategories[i].sub)
  //       console.log("equal" )
  //      }
  //      else {
  //       formData.append('subcategory_ids['+i+']['+j+']',f.subcategories[i].sub)
  //       console.log("not equal" )
  //       break;
        
  //      }
       
  //    } 
  // }
 