import { Component, OnInit ,Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
// import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
 
@Component({
  selector: 'app-answer-products',
  templateUrl: './answer-products.component.html',
  styleUrls: ['./answer-products.component.scss']
})
export class AnswerProductsComponent implements OnInit {
  @Input() pageIndex: number;
  @ViewChild('search')search:ElementRef ;
  public pieceNumber :any
  public paginator=100;
  products=[]
  toppings = new FormControl('');
  form:FormGroup;
  submitted:boolean =false;
  status:boolean=false ;
  public filterByBrand:any
  public answerProducts=[]
  x
   constructor(private spinner:NgxSpinnerService,  private formbuilder:FormBuilder, private service:GlobalService,   public dialogRef: MatDialogRef<AnswerProductsComponent>,  @Inject(MAT_DIALOG_DATA)public data:any,private dialog:MatDialog) { }

  ngOnInit(): void {
    
    this.pageIndex=0 ;
    //("Answers",this.data)
    this.filterByBrand = {
       brand_ids:[this.data?.brand], 
       page:1
    }
     this.filterProducts(this.filterByBrand)
      //  this.form=this.formbuilder.group({
      //   name:[null,Validators.required],
      // })
  }
  
  closeModel(){
    this.dialogRef.close();
  }
  resetProducts(){
  console.log( this.search.nativeElement.value)
     this.search.nativeElement.value=='' 
     this.x=''
      this.filterByBrand = {
        brand_ids:[this.data?.brand], 
        page:1
     }
      this.filterProducts(this.filterByBrand)
    
  }

  onPaginateChange(event){
  //  console.log("onPaginateChange",this.filterByBrand)
    this.pageIndex=event.pageIndex+1
   this.filterProducts(this.filterByBrand)
  }

  AddAnswer() {
   // console.log("answerProducts",this.answerProducts)
    let submitted_form={
      instant_order_id:this.data.instant,
      products_ids:this.answerProducts
    }
    this.spinner.show()
    this.service.addAnswerToInstantOrder(submitted_form).subscribe((res:any)=>{
      this.spinner.hide()
    //  console.log("res answer" , res)
      if(res.status==true) {
        Swal.fire(
          `Success`,
          `${res.message}`,
          `success`
        )
        this.dialog.closeAll();
      }
        else {
          Swal.fire(
            `Fail`,
            `${res.errors[0]}`,
            `error`
          )
      }
    })
  }

  filterByPieceNumber(){
   this.pieceNumber=this.search.nativeElement.value
   console.log("this.pieceNumber",this.pieceNumber)
   let f ={
    piece_number:this.pieceNumber,
    brand_ids:[this.data?.brand], 
    page:1
   }
   this.filterProducts(f)
  }

  // check_items:any =[] ;
  // doChange(event,product){
  // console.log("checked",product)
  //      if(event.checked==true) {
  //         product.is_checked=true
  //         this.answerProducts.push(product.id)
  //         this.check_items.push( product.name )
  //      }
  //      else {
  //         product.is_checked=false
  //         let deleted_index ;
  //         deleted_index= this.answerProducts.indexOf(product.id)
  //         this.answerProducts.splice(deleted_index , 1); 

  //        let deleted_item= this.check_items.indexOf(product.name)
  //        this.check_items.splice(deleted_item , 1); 
  //       } 
  //      console.log("check_items",this.check_items)
  //  }

  check_items:any =[] ;
  doChange(event,product){
    let obj ;
  console.log("checked",product)
       if(event.checked==true) {
          product.is_checked=true
         obj={name:product.name , piece:product.piece_number}
          this.answerProducts.push(product.id)
          this.check_items.push(obj)
       }
       else {
          product.is_checked=false
          let deleted_index ;
          deleted_index= this.answerProducts.indexOf(product.id)
          this.answerProducts.splice(deleted_index , 1); 
          obj={name:product.name , piece:product.piece_number}
          let deleted_item=  this.check_items.findIndex(object => {
            return object.name === product.name  ;
          });
       //  let deleted_item= this.check_items.indexOf(obj)
         this.check_items.splice(deleted_item , 1); 
        } 
       console.log("check_items",this.check_items)
   }

  filterProducts(obj){
    this.spinner.show()
    this.service.filterProduct(obj).pipe(map(res=>res['data'])).subscribe((response:any)=>{
      this.spinner.hide()
       console.log("products",response?.products.data)
      this.products=response?.products.data
      this.products.forEach( product =>{
        product.is_checked=false
      })
      
      this.paginator=Number(response.products.per_page)* Number(response.products.last_page)
  //    console.log("pro ",response,this.paginator)
    })
  }

   
  get f() {return this.form.controls}
}
