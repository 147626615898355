import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
 
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import {  NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';
import { EditProductComponent } from '../edit-product/edit-product.component';
import { HttpClient } from '@angular/common/http';
 
import { delay } from 'rxjs/operators';
import { DynamicComponent } from '../dynamic/dynamic.component';
 
@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductsComponent implements OnInit {
  categories =[]
  category_change=[]
  subcategories=[]
  subcategory_change=[]
  models = [];
  brands = [];
  products :any = [] ;
  change_model:any=[];
  change_brand:any=[];
  param1:any ; 
  param2:any ; 
  param3:any ; 
  param4:any ; 
  sub_param:any;
  @Input() pageIndex: any; 
  paginator:any;
  @ViewChild('vin') vin:ElementRef  ;
  bool_category:boolean=false ;
  bool_brand:boolean=false ;

  constructor( private http:HttpClient,private route: ActivatedRoute,private dialog:MatDialog, private spinner:NgxSpinnerService,private service: GlobalService , private router:Router) {
    this.param1= this.route.snapshot.paramMap.get('category');
    //console.log("param1", this.param1)
   
    this.param2= this.route.snapshot.paramMap.get('subcategory');
    //console.log("param2", this.param2)
 
    this.param3= this.route.snapshot.paramMap.get('brand');
   // console.log("param3", this.param3)
   
    this.param4= this.route.snapshot.paramMap.get('model');
   // console.log("param4", this.param4)
     
    this.categoryList();
    this.getAllBrands() ;
      //  this.getAllSubcategories([this.param1])
      //  this.getAllModels([this.param3])
   
  }

  ngOnInit(): void {
    this.bool_category=false;
    this.bool_brand=false;
    this.pageIndex=0 ;
    this.vin =null ;
   //("this.param4",this.param4)
    // setTimeout(function () {
      let form={
        category_ids:[ ] ,
        subcategory_ids:[ ],
        brand_ids:[ ] , 
        model_ids:[ ],
        page:this.pageIndex,
      }
     // console.log("form",form)
      this.spinner.show();
      this.service.filterProduct(form).pipe(delay(2000)).subscribe((res:any)=>{
        this.spinner.hide();
        this.products=res['data'].products.data
 
      //  console.log("res",res)
      this.paginator=res['data'].products.last_page*res['data'].products.per_page
     console.log("filterrr" ,this.products )
     console.log( " paginator", this.paginator ,res['data'].products.last_page*res['data'].products.per_page)
      })
     
 // }, 2000);
  //console.log("pageIndex" ,this.pageIndex)
  }

  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
   // console.log(JSON.stringify( this.pageIndex  ) , this.pageIndex );
    this.spinner.show();
    this.filter()
    this.spinner.hide();
  }

  changeVin() {
   // console.log("vin",this.vin.nativeElement.value)
  }

  categoryList(){
    this.bool_category=false
    this.service.allCategories().subscribe((res:any)=>{
      this.categories = res['data']
     //  console.log("All Categories" ,this.categories)
      this.category_change[0]=this.param1
      //this.getAllSubcategories(this.category_change)
    })
  }

  changeCategory(event) {
    this.bool_category=true
    this.category_change[0]=event.target.value
    if(this.category_change[0]!=0){
      this.getAllSubcategories(this.category_change)
    }
   
    //console.log("search category id",this.category_change[0])
  }

  getAllSubcategories(category){
    this.category_change[0]=category[0]
    this.service.getSubcategoryByCategoryId(this.category_change).subscribe((res:any)=>{
     this.subcategories=res['data']
       // console.log("All SubCategories" , this.subcategories)
      
     })
  }

  changeSubcategory(event){
  this.subcategory_change[0]=event.target.value
  //console.log("search subcategory id",this.subcategory_change[0])
  }

  getAllBrands(){
    this.bool_brand=false
    this.service.getBrands().subscribe((res:any)=>{ 
      this.brands=res['data'] ;
     // console.log("All Brands" , this.brands)
      this.change_brand[0]=this.param3 ;
     
    //  this.getAllModels(this.change_brand);
    })
  }

  changeBrands(event) {
    this.bool_brand=true
    this.change_brand[0]=event.target.value
    if(this.change_brand[0]!=0){
      this.getAllModels(this.change_brand)
    }
  
   // console.log("search brands id" , this.change_brand[0])
  }

  getAllModels(model){
    this.change_brand[0]=model[0]
   //this.spinner.show();
     this.service.getModelsByBrandId(this.change_brand).subscribe((res:any)=>{
    //  console.log("All Models" ,res)
     this.models=res['data']
     //   console.log("All Models" , this.models)
     })
  }

  changeModels(event) {
   this.change_model[0]=event.target.value ;
  // console.log("search models id" , this.change_model[0])
  }

  filter(){
   let form :any ;
      
      form={
        category_ids:this.category_change ,
        subcategory_ids:this.subcategory_change ,
        brand_ids:this.change_brand , 
        model_ids:this.change_model , 
        page:this.pageIndex,
        piece_number:this.vin.nativeElement.value
        }
        // if category and brand dropdown not changed or user choose option all categories or all brands
        if(this.bool_category==false || this.category_change[0]==0){
         // console.log("category_change",this.category_change[0])
          form.category_ids=[]
        }
        if(this.bool_brand==false || this.change_brand[0]==0) {
          //console.log( "change_brand",this.change_brand[0])
          form.brand_ids=[]
        }
        if(this.subcategory_change[0]==0) {
         // console.log( "change_subcategory",this.subcategory_change[0])
          form.subcategory_ids=[]
        }
        if(this.change_model[0]==0) {
          //console.log( "change_model",this.change_model[0])
          form.model_ids=[]
        }
      console.log("form",form)
      this.spinner.show();
      this.service.filterProduct(form).subscribe((res:any)=>{
        this.spinner.hide();
        this.products=res['data'].products.data
       console.log("filter2" ,this.products)
        // this.products=[...this.products].reverse()
        this.paginator=res['data'].products.last_page*res['data'].products.per_page
        console.log( " paginator", this.paginator ,res['data'].products.last_page*res['data'].products.per_page)
      })
     // this.router.navigate(['/app/products/lists',this.category_change[0],this.subcategory_change[0],this.change_brand[0],this.change_model[0]])
  }
   public edit_product:any ;
  onEditModel(model_id) {
   // console.log("model", model_id)
   
    this.service.getProductById(model_id).subscribe((res:any)=>{
      this.edit_product=res['data']
   })
 // console.log("onEditModel", this.edit_product)

    let editCategories = [], editSubcategories = [], editBrands =[], editModels =[]
    
    this.edit_product.subcategories.forEach(element => {
       editCategories.push(element.id)
    });
    this.edit_product.categories.forEach(element => {
      editSubcategories.push(element.id)
    });
    this.edit_product.brands.forEach(element => {
      editBrands.push(element.id)
    });
    this.edit_product.models.forEach(element => {
      editModels.push(element.id)
    });
  
    let dialogRef = this.dialog.open( EditProductComponent, {
      data: this.edit_product,
      height: '800px',
     });
    dialogRef.afterClosed().subscribe( res => {
    // console.log("editCategories",editCategories[0],editSubcategories[0],editBrands[0],editModels[0])
  
     this.param1=editCategories[0] 
     this.param2=editSubcategories[0]
     this.param3=editBrands[0]
     this.param4=editModels[0]
    
      let form={
        category_ids:[this.param1] ,
        subcategory_ids:[this.param2] ,
        brand_ids:[this.param3], 
        model_ids:[this.param4],   
        page:this.pageIndex,
        }
        this.spinner.show();
        this.service.filterProduct(form).subscribe((res:any)=>{
        this.spinner.hide();
          this.products=res['data'].products
          this.products=[...this.products].reverse()
        
        })
    this.router.navigate(['/app/products/lists',editCategories[0],editSubcategories[0],editBrands[0] ,editModels[0] ])
    location.reload()
  })
  }

  public dynamic_product;

  openDynamic(model_id){
    this.service.getProductById(model_id).subscribe((res:any)=>{
      this.dynamic_product=res['data']
    
    let dialogRef = this.dialog.open( DynamicComponent, {
      data: this.dynamic_product,
      height: '800px',
     // width: '1000px',
     });
    })
  }

  onDeleteModel(product) {
  //  console.log("product",product)
    this.spinner.show();
    this.service.deleteProducts(product.id).subscribe( (deleteResponse:any) => {
      if(deleteResponse['status']==true){
       // console.log(deleteResponse);
        this.spinner.hide();
        Swal.fire(
          'نجااااح',
          'تم حذف المنتج  بنجاح',
          'success'
          )
         // this.router.navigate(['/app/products/lists',this.param1,this.param2 ,this.param3,this.param4]);
        //  location.reload()
            let form={
              category_ids:[] ,
              subcategory_ids:[] ,
              brand_ids:[], 
              model_ids:[] ,
              page:this.pageIndex, 
              }
        
              this.service.filterProduct(form).subscribe((res:any)=>{
                this.products=res['data'].products.data
             //   this.products=[...this.products].reverse()
                console.log("filter" ,this.products ) 
             this.paginator=res['data'].products.last_page*res['data'].products.per_page;
              console.log( " paginator", this.paginator ,res['data'].products.last_page*res['data'].products.per_page)
              })
           
            
          }
      else {
        this.spinner.hide();
        let error=deleteResponse['errors']
        Swal.fire(
          'خطأ',
          `${error[0]}`,  
          'error'
          )
      }
     
    },
    (err:any)=>{
      this.spinner.hide();
      let error=err['errors']
      Swal.fire(
        'خطأ',
        `${error[0]}`,  
        'error'
        )
    });

    
     
    
  }
  
 
}
