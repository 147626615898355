import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddFooterComponent } from './add-footer/add-footer.component';
import { EditFooterComponent } from './edit-footer/edit-footer.component';
 


const routes: Routes = [
  {path:'add',component: AddFooterComponent,data:{title:''} },
  {path:'edit',component:EditFooterComponent,data:{title:''} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FooterRoutingModule { }
