import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {

  form:FormGroup;
  submitted:boolean=false;
  constructor(
    private formbuilder:FormBuilder,
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    private router:Router
    ) { }

  ngOnInit(): void {
    this.form=this.formbuilder.group({
      name:['',Validators.required],
      name_en:['',Validators.required],
      description:['',Validators.required],
    })
  }

  files: File[] = [];

onSelect(event) {
 // console.log(event.addedFiles[0]);
  this.files=[]
  this.files.push(...event.addedFiles);
  event.addedFiles.forEach(file=>{
    console.log("fileSize",file,file.size)
    if(file.size>1048576) // 1MB 
    {
      Swal.fire( 
        `Fail`,
        `Image size must be 1 megabytes or less `,
        `error`)
    }
   })
}

onRemove(event) {
  //console.log(event);
  this.files.splice(this.files.indexOf(event), 1);
}

get f() {return this.form.controls}
  submit(){
    this.submitted=true
    let form = {
      name:this.form.value.name,
      name_en:this.form.value.name_en,
      description:this.form.value.description,
      logo_image:this.files[0]
    }
    this.spinner.show()
    this.service.addBrand(form).subscribe((res:any)=>{
    this.spinner.hide()
    if(res['status']==true) {
      Swal.fire(
        'نجاح',
        `${res['message']}`,
        'success'
      )
      this.router.navigate(['/app/brands/list'])
    }
    else {
      let error = res['errors']
      Swal.fire(
        'خطأ',
         `${error[0]}`,
        'error'
      )
    }
 
    })
  }

}
