import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ShippingFeeRoutingModule } from './shipping-fee-routing.module';
import { AddShippingFeeComponent } from './add-shipping-fee/add-shipping-fee.component';
import { ListShippingFeeComponent } from './list-shipping-fee/list-shipping-fee.component';
 


@NgModule({
  declarations: [AddShippingFeeComponent,ListShippingFeeComponent],
  imports: [
    CommonModule,
    ShippingFeeRoutingModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class ShippingFeeModule { }
