import   Swal  from 'sweetalert2';
import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderDetailsComponent } from '../list/order-details/order-details.component';
import { AnswerProductsComponent } from '../answer-products/answer-products.component';
import { SpecialAnswerComponent } from '../special-answer/special-answer.component';

@Component({
  selector: 'app-special-orders',
  templateUrl: './special-orders.component.html',
  styleUrls: ['./special-orders.component.scss']
})
export class SpecialOrdersComponent implements OnInit {
  @Input() pageIndex: number;
  
  public questionPaginator=100;
  public answerPaginator=100;
  orders=[];
  type='new';

  public answers=[];
  public questions=[];
 
  show_answered_orders=false; 
  show_not_unswered_orders=true;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.pageIndex=1;
    this.instantOrders(this.pageIndex);
  }
  
  onQuestionPaginateChange(event){
    this.questionPaginator=event.pageIndex+1
    this.instantOrders(this.questionPaginator)
  }
  onAnswerPaginateChange(event){
    this.answerPaginator=event.pageIndex+1
    this.instantOrders(this.answerPaginator)
  }
  instantOrders(page){
    this.answers=[];
    this.questions=[];
    this.spinner.show()
    this.service.allInstantOrders(page).pipe(map(res=>res['data'])).subscribe((res:any)=>{
    this.spinner.hide()
    console.log("All Instatn Orders", res )
    this.orders=res['data']
     for (let i=0 ; i<this.orders.length ; i++){
      if(this.orders[i].answers.length!=0){
       this.answers.push(this.orders[i]);
       console.log("answers",this.answers)
       this.questionPaginator=res.total
      }
      else {
       this.questions.push(this.orders[i]);
       console.log("questions",this.questions)
       this.answerPaginator=res.total
      }
     }
    //  this.paginator=res.per_page*res.last_page
    
    // console.log("paginator",this.paginator)
   })
  }

  viewAnswers(answers){
    let dialogRef = this.dialog.open(SpecialAnswerComponent , {
      data:answers,
      height: '500px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
     
    });
  
  }
 
  getOrders(type){
    if(type==0){
      this.show_not_unswered_orders=true;
      this.show_answered_orders=false; 
      this.pageIndex=0
    }
    else {
      this.show_answered_orders=true; 
      this.show_not_unswered_orders=false;
      this.pageIndex=0
    }
    
  }

  editApp(brand_id:any , order_id:any){
    let dialogRef = this.dialog.open(AnswerProductsComponent, {
      data:{'brand':brand_id,'instant':order_id},
      height: '800px',
      width: '800px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.instantOrders(this.pageIndex);
    });
  }

  deleteApp(){
    Swal.fire(
      'نجاح',
      'تم حذف التطبيق بنجاح',
      'success'
      )
  }

  // orderDetails(order){
  //   let dialogRef = this.dialog.open(OrderDetailsComponent, {
  //     data:order,
  //     height: '600px',
  //     width: '600px',
  //   });
  // }
 
}
