import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent implements OnInit {
  form:FormGroup;
  permissions:any=[];
  permissions_arr:any=[];
  image_edit=false;
  baseUrl=environment.baseURL;
  submitted:boolean = false
  constructor(
    private formbuilder:FormBuilder,
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    private router:Router,
    private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { this.permissionsList()}

  ngOnInit(): void {
    console.log("edit permissions",this.data)
    this.form=this.formbuilder.group({
      name:[this.data.name,Validators.required],
      email:[this.data.email,Validators.required],
      password:[null],
      // permissions_ids:[this.data.permission,Validators.required],
    })

    for(let i=0 ; i<this.data.permissions.length ; i++){
      this.permissions_arr[i]=this.data.permissions[i].id 
     // console.log("this.data.permission[i].id  " ,this.data.permissions[i].id  )
   }
  }
  permissionsList(){
    this.spinner.show()
    this.service.showAllPermissions().pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()
    this.permissions=res
    })
  }
 
  onChangePermissions(event:any){
    this.permissions_arr=event.value
     console.log("events",this.permissions_arr)
  }
  get f() {
    return this.form.controls;
  }
  submit(){
    this.submitted=true;
    let form={
      subadmin_id:this.data.id || null,
      name :this.form.value.name || null,
      email:this.form.value.email || null,
      password:this.form.value.password ,
      permissions_ids:this.permissions_arr  || null,
    }
    console.log("form",form)
    if(this.form.invalid){return}
    this.spinner.show()
    this.service.editAllSubdmainPermissions(form).subscribe(res=>{
    this.spinner.hide()
    Swal.fire(
        'نجاح',
        'تم تعديل بيانات المستخدم بنجاح',
        'success'
      )
      this.dialog.closeAll()
    })
  }
}


// validities