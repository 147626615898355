import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-shipping-fee',
  templateUrl: './add-shipping-fee.component.html',
  styleUrls: ['./add-shipping-fee.component.scss']
})
export class AddShippingFeeComponent implements OnInit {
  form:FormGroup;
  info;
  submitted=false;
  constructor(
    private formbuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private router:Router
    ) { }


  ngOnInit(): void {
    this.form=this.formbuilder.group({
      tax:['',Validators.required],
      shipping_fee:['',Validators.required],
   })
  this.Tax_FeeList()
  }

  Tax_FeeList(){
    this.service.getTaxAndShippingFee().subscribe(res=>{
   //   console.log("Tax_FeeList",res)
      this.info=res['data'] 
      this.form.patchValue({
        tax:this.info.tax,
        shipping_fee:this.info.shipping_fee
      });
    })
  }

  get f(){return this.form.controls}

  submit(){
    this.submitted=true;
    if(this.form.invalid){ return}
    this.spinner.show()
    this.service.SetTaxAndShippingFee(this.form.value.tax ,this.form.value.shipping_fee).subscribe(res=>{
      this.spinner.hide()
      if(res['status']==true){
        Swal.fire(
          'نجااااح',
          'تم إضافة كود الخصم و ضريبة الشحن',
          'success'
          )
          this.router.navigate(['/app/shipping/list'])
      }
      else {
        let error=res['errors']
        Swal.fire(
          'خطأ',
          `${error[0]}`,
          'error'
          )
      }
   
    })
  }
}
