import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from '../auth/authentication.service';
import * as  moment from 'moment' ;
import { FormControl } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public timeNow: Date = new Date();
  public welcomePhrase;
  user;
  statistics;
  brands:any=[]
  ads = []
  categories = []
  countries = []
  citties = []
  href:any;
  // public sentDate:any;
  result:any=null;
  selected:Date ;
  satrtDate:Date ;
  @ViewChild(MatCalendar, {static: false}) calendar: MatCalendar<Date>;
  toggle_statistics:boolean=false ;
  form:any = {}
  today =moment().toDate()
   choices = [
              {key:"Today" , value:0},  
              {key:"yesterday" , value:1},
              {key:"Last 7 days" , value:2},
              {key:"This month" , value:3},
              {key:"Last month" , value:4},
              {key:"This quarter" , value:5},
              {key:"Last quarter" , value:6},
              {key:"This year" , value:7},
              {key:"Lifetime" , value:8}
             ]

  constructor(
    private authentication:AuthenticationService,
    private service:GlobalService,
    private spinner:NgxSpinnerService,private router: Router
    ) { 
    this.authentication.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
  //  console.log("uid",this.user.data.user?.permissions[0].uid)
   if(this.user.data.user.permissions==null) { // show statistics  admin
      this.toggle_statistics=true ;
   }else if (this.user.data.user.permissions && this.user.data.user.permissions[0].uid==1) { // show statistics  sub_admin
    this.toggle_statistics=true ;
   }
   else {  // hide statistics  unauthorized
    this.toggle_statistics=false ;
   }
     

   this.href = this.router.url;
   this.router.events.subscribe((event: NavigationStart) => {
     if (event.navigationTrigger === 'popstate') {
      if(this.href='/' && this.user!=null) {
          this.router.navigate(['/home'])
        }
        else if  (this.href='/' && this.user==null){
          this.router.navigate(['/'])
        }
      }
   });

   
  }
 
  ngOnInit(): void {
   
    this.form={
      date_from:moment(this.today ).format("YYYY-MM-DD"),
      date_to:moment(this.today ).format("YYYY-MM-DD") ,
      is_lifetime:null,
    }
    this.selected=moment(this.today ).toDate()
    this.filterOrderByDate(this.form)
    this.filterOrderByBrand(this.form);
  }

  onSelect(event ,status) {
    if(status=='true'){
      this.selected=event;
     if((moment(this.selected).format("YYYY")==moment(this.today).format("YYYY")) ) { // current year

      if(moment(this.selected).format("MM") == moment(this.today).format("MM"))  { // same month
        if (moment(this.selected).format("DD")<moment(this.today).format("DD"))  { 
           this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
           this.form.date_to=moment(this.today ).format("YYYY-MM-DD")  
           this.form.is_lifetime=null
        } 
        else if((moment(this.selected).format("DD"))> (moment(this.today).format("DD"))){
          this.form.date_from=moment(this.today).format("YYYY-MM-DD")
          this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
          this.form.is_lifetime=null 
       }
      }

      if(moment(this.selected).format("MM")< moment(this.today).format("MM"))  { // selected month < current month
         this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
         this.form.date_to=moment(this.today ).format("YYYY-MM-DD")  
         this.form.is_lifetime=null
      }

      if(moment(this.selected).format("MM")> moment(this.today).format("MM"))  { // selected month > current month
        this.form.date_from=moment(this.today).format("YYYY-MM-DD")
        this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
        this.form.is_lifetime=null
      }
    }

     if((moment(this.selected).format("YYYY")<moment(this.today).format("YYYY")) ) {
        this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
        this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
        this.form.is_lifetime=null
     }
     if((moment(this.selected).format("YYYY")>moment(this.today).format("YYYY")) ) {
      this.form.date_from=moment(this.today).format("YYYY-MM-DD")
      this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
      this.form.is_lifetime=null
     }

     this.filterOrderByDate(this.form)
     this.filterOrderByBrand(this.form)
     
  } //true case
   else{
     this.selected=event;
    }
  
}
  // onSelect(event ,status) {
  //     if(status=='true'){
  //       this.selected=event;
  //       //console.log("moment",moment(this.selected).format("D"))
  //      if((moment(this.selected).format("MM") == moment(this.today).format("MM")) && (moment(this.selected).format("DD")<moment(this.today).format("DD"))) {
  //         this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.today ).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null
  //       }

  //       else if((moment(this.selected).format("MM")==moment(this.today).format("MM")) && (moment(this.selected).format("DD")>  (moment(this.today).format("DD"))  )) {
  //         this.form.date_from=moment(this.today).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null 
  //       }

  //       else if((moment(this.selected).format("MM")<moment(this.today).format("MM")) ){
  //         this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null
  //       }

  //       else if((moment(this.selected).format("MM")>moment(this.today).format("MM")) ){
  //         this.form.date_from=moment(this.today).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null
  //       }
  //       else if((moment(this.selected).format("YYYY")<moment(this.today).format("YYYY"))&&(moment(this.selected).format("MM")>moment(this.today).format("MM")) ){
  //         this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null
  //        console.log("issue1",this.form)
  //       }
  //       else if((moment(this.selected).format("YYYY MM")<moment(this.today).format("YYYY MM")) ){
  //         this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null
  //        console.log("issue2",this.form)
  //       }

  //       else if((moment(this.selected).format("YYYY")>moment(this.today).format("YYYY")) ){
  //         this.form.date_from=moment(this.today).format("YYYY-MM-DD")
  //         this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
  //         this.form.is_lifetime=null
        
  //        console.log("issue3",this.form)
  //       }
  //       this.filterOrderByDate(this.form)
  //     }
  //     else{
    
  //       this.selected=event;
  //     }

  //     this.selected=event;
  //     console.log("moment",moment(this.selected).format("D"))
  //    if((moment(this.selected).format("MM") == moment(this.today).format("MM")) && (moment(this.selected).format("DD")<moment(this.today).format("DD"))) {
  //       this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
  //       this.form.date_to=moment(this.today ).format("YYYY-MM-DD")  
  //          this.form.is_lifetime=null
  //       console.log("same month , previous day" , this.selected , this.today)
     
  //     }
  //     else if((moment(this.selected).format("MM")==moment(this.today).format("MM")) && (moment(this.selected).format("DD")>  (moment(this.today).format("DD"))  )) {
  //       this.form.date_from=moment(this.today).format("YYYY-MM-DD")
  //       this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
  //           this.form.is_lifetime=null
  //       console.log("same month ,  next day" , this.selected , this.today)
         
  //     }
  //     else if((moment(this.selected).format("MM")<moment(this.today).format("MM")) ){
  //       this.form.date_from=moment(this.selected).format("YYYY-MM-DD")
  //       this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
  //           this.form.is_lifetime=null
  //     }
  //     else if((moment(this.selected).format("MM")>moment(this.today).format("MM")) ){
  //       this.form.date_from=moment(this.today).format("YYYY-MM-DD")
  //       this.form.date_to=moment(this.selected).format("YYYY-MM-DD")  
  //          this.form.is_lifetime=null
  //     }
    
  //     this.spinner.show()
  //     this.service.filterOrders(this.form).subscribe((res:any)=>{
  //     this.spinner.hide()
  //       this.result=res.data;
  //       console.log("res",res)
  //     })
       
  //  }

  chooseDate(value){
    switch(value) {
      case 0: //today ;
        this.satrtDate =moment().toDate()  
        this.form.date_from=moment(this.satrtDate).format("YYYY-MM-DD")
        this.form.date_to=moment(this.satrtDate).format("YYYY-MM-DD")
        this.form.is_lifetime=null
        break;
      case 1: //yesterday ;
        this.satrtDate =(moment().subtract(1, 'days')).toDate()  
        this.form.date_from=moment(this.satrtDate).format("YYYY-MM-DD")
        this.form.date_to=moment(this.satrtDate).format("YYYY-MM-DD")
        // this.form.date_to=moment(this.today).format("YYYY-MM-DD")
        this.form.is_lifetime=null 
        break;
      case 2: // last 7 days ;
        this.satrtDate =(moment().subtract(7, 'days')).toDate()   
        this.form.date_from=moment(this.satrtDate).format("YYYY-MM-DD")
        this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
        this.form.is_lifetime=null
         break;
      case 3: // this month
        this.satrtDate =(moment().endOf('month')).toDate()  
        // this.form.date_from=moment(this.today).format("YYYY-MM-DD")
        this.form.date_from=moment(this.today).startOf('month').format("YYYY-MM-DD")
        this.form.date_to=moment(this.satrtDate).format("YYYY-MM-DD")  
        this.form.is_lifetime=null
        break;
      case 4: // last month
        this.satrtDate = (moment().subtract(1,'months').endOf('month')).toDate()  
       // console.log("Last Month",(moment(this.today).subtract(1,'months').startOf('month')).format("YYYY-MM-DD")  )
        this.form.date_from= (moment().subtract(1,'months').startOf('month')).format("YYYY-MM-DD")
        this.form.date_to= moment(this.satrtDate).format("YYYY-MM-DD")  
        // this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
        this.form.is_lifetime=null
        break;
      case 5: //This quarter
        this.satrtDate =(moment().quarter(moment(this.today).quarter()).endOf('quarter')).toDate()  
        this.form.date_from=moment().quarter(moment(this.today).quarter()).startOf('quarter').format("YYYY-MM-DD");
        this.form.date_to=moment().quarter(moment(this.today).quarter()).endOf('quarter').format("YYYY-MM-DD");
        this.form.is_lifetime=null
       // console.log("This quarter",this.form)
        break;
      case 6: //Last quarter
       let last_q =(moment(this.today).utc().quarter())-1
       this.satrtDate =(moment().subtract(last_q, 'Q').endOf('quarter')).toDate()  
       this.form.date_from=moment().subtract(last_q, 'Q').startOf('quarter').format("YYYY-MM-DD");
       this.form.date_to=moment().subtract(last_q, 'Q').endOf('quarter').format("YYYY-MM-DD") ;
       this.form.is_lifetime=null
        break;
      case 7: //This year
      this.satrtDate = (moment().startOf('year')).toDate()
      console.log("this.satrtDate",this.satrtDate)
      this.form.date_from=(moment().startOf('year')).format("YYYY-MM-DD")
      this.form.date_to=moment(this.today).format("YYYY-MM-DD") 
      // this.satrtDate = (moment().startOf('year')).toDate()
      // this.form.date_from=(moment().subtract(1, "year").startOf('year')).format("YYYY-MM-DD") 
      // this.form.date_to=(moment().startOf('year')).format("YYYY-MM-DD")

        // this.satrtDate = (moment().subtract(1, "year")).toDate()
        // this.form.date_from=moment(this.satrtDate).format("YYYY-MM-DD")
        // this.form.date_to=moment(this.today).format("YYYY-MM-DD") 
        this.form.is_lifetime=null 
        break;
      case 8: //Lifetime
        this.satrtDate =moment().toDate()   
        this.form.date_from=moment(this.satrtDate).format("YYYY-MM-DD")
        this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
        this.form.is_lifetime=1
        break;
      default:
        this.satrtDate =moment().toDate()  //today ;
        this.form.date_from=moment(this.satrtDate).format("YYYY-MM-DD")
        this.form.date_to=moment(this.today).format("YYYY-MM-DD")  
        this.form.is_lifetime=null
    }
     this.onSelect(this.satrtDate,'false') 
    this.calendar._goToDateInView(this.satrtDate , 'month');
   // console.log("form",this.form)
    this.filterOrderByDate(this.form)
    this.filterOrderByBrand(this.form)
  }

  filterOrderByDate(form){
   // console.log("form",form)
    this.spinner.show()
    this.service.filterOrders(form).subscribe((res:any)=>{
    this.spinner.hide()
      this.result=res.data;
     // console.log("result", this.result)
    })
   }

   filterOrderByBrand(form){
    this.spinner.show()
    this.service.filterBrands(form).subscribe((res:any)=>{
    this.spinner.hide()
    this.brands=res.data;
    console.log("filterBrands", this.brands)
    })
   }
  //   getLastWeeksDate() {
  //   const now = new Date();
  //   return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  // }

  // getDate(){
  //   setInterval(() => { this.timeNow = new Date() }, 1);
  //   var myDate = new Date();
  //   var hrs = myDate.getHours();
  //   if (hrs < 12)
  //     this.welcomePhrase = 'صباح الخير';
  //   else 
  //     this.welcomePhrase = 'مساء الخير';
  // }

  // homeStatistics(){
  //   this.spinner.show()
  //   this.service.homeStatistics().pipe(map(res=>res['data'])).subscribe(res=>{
  //     this.spinner.hide()
  //     console.log('Home statistics')
  //     console.log(res)
  //     this.statistics=res
  //   })
  // }
 
 
  
}
