import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-compatible-models',
  templateUrl: './compatible-models.component.html',
  styleUrls: ['./compatible-models.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CompatibleModelsComponent implements OnInit {
  // model
  // dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  models_of_brands = [];
  years = []
  modelId = null
  constructor(
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    public dialogRef: MatDialogRef<CompatibleModelsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    this.models_of_brands = data.models_of_brands;
    this.years = data.years;
      

    console.log(data)
    if(data && data.edit == true){
      this.models_of_brands.push(data.model.model)
      this.selectedItems = (data.model.dates)
      this.modelId = data.model.model.id
    }
    /**
     * edit
: 
true
model
: 
dates
: 
[{…}]
isUpdate
: 
undefined
model
: 
{id: 24, brand_id: 13, name: 'سوناتا', created_at: '2022-11-23T05:48:04.000000Z', description: null}
     */
  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'date',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
    console.log(item);
    // this.selectedItems.push(item)
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onChangeCompatibleProductModel(e,i){
    this.modelId = e.value
  }
  save(){
    if(this.selectedItems.length <= 0){
      alert('يجب اختيار السنوات')
      return
    }else if(!this.modelId){
      alert('يرجي اختيار الموديل')
      return
    }
    
    console.log(this.selectedItems)
    console.log(this.modelId)

    this.dialogRef.close({
      data:{
        dates : this.selectedItems,
        // modelId : this.modelId,
        model : this.models_of_brands.filter(model => model.id == this.modelId)[0],
        isUpdate : this.data.edit || false
      }
    });

  }

}
