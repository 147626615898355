import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewsRoutingModule } from './reviews-routing.modules'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatIconModule } from '@angular/material/icon';
import { AddReviewsComponent } from './add-reviews/add-reviews.component';
import { EditReviewsComponent } from './edit-reviews/edit-reviews.component';
import { ListReviewsComponent } from './list-reviews/list-reviews.component';
 


@NgModule({
  declarations: [AddReviewsComponent,EditReviewsComponent,ListReviewsComponent ],
  imports: [
    CommonModule,
    ReviewsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    MatIconModule
  ]
})
export class ReviewsModule { }
