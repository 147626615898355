import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';



@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
 
/////////////////SCAR///////////////////
changeCat:boolean=false 
changeCat2:boolean=false;
form:FormGroup;
submitted=false ;
category:any ;
categories:any=[];
mainCategories:any=[]
all_categories:any=[]
subcategories_of_maincategories:any=[]
subCategories:any=[];
all_brands:any=[]
models_of_brands:any=[]
brands:any=[];
models:any=[];
years:any=[];
public product_compatibles_model:any=[];
manufacture_date_id:any=[] ;
product_compatibles_date:any=[];
image_edit=false;
image_edit2=false;
 
d_images:any =[]
images:any=[]
model_str:any
subcategory_str:any
selected_date:any;

subCats:any=[]
modBrand:any=[]
@ViewChild ('ref')ref:ElementRef;
teamInitial :any ;
public form_compat_model=[] ; 
public form_arr=[];
public unique =[]// fake_send arr
public redundant_arr=[];
public compat=[];
public items =[]; 
mo=['22','23']
////////////////////////////////////////
  constructor(
    private formbuilder:FormBuilder,
    private service:GlobalService,
    private spinner:NgxSpinnerService,
    private router:Router,
    private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,
    ) { 
    
    }
 
 model_obj:any  ;  model_arr=[];   subcategory_obj:any ; subcategory_arr=[]

  ngOnInit(): void {
  
  this.changeCat=false ;
  
  this.images[0]=this.data.imagePath
  this.d_images=this.data.descriptionImagesPaths
  // this.manufacture_date_id=+this.data.manufacture_date_id ;
  
  for(let i=0 ; i<this.data.categories.length ; i++){
      this.categories[i]=this.data.categories[i].id 
      this.categories=this.convertArrayOfstringToArrayofInt(this.categories)
  }
 
  for(let i=0 ; i<this.data.brands.length ; i++){
     this.brands[i]=this.data.brands[i].id 
     this.brands = this.convertArrayOfstringToArrayofInt(this.brands)
   
  }
 
  for(let i=0 ; i<this.data.compatibles.length ; i++){
    
   this.product_compatibles_model[i]=this.data.compatibles[i].model.id
   this.product_compatibles_model = this.convertArrayOfstringToArrayofInt(this.product_compatibles_model)
   
       
  }

  for(let i=0 ; i<this.data.compatibles.length ; i++){
 
  for (let y=0; y<this.data.compatibles[i].manufacture_dates.length ; y++){
    this.manufacture_date_id[y]=this.data.compatibles[i].manufacture_dates[y].id

  }
 
    
}
  // manufacture_dates

  //models user not change
  for(let i=0 ; i<this.data.models.length; i++){
     this.model_obj={cat:this.data.models[i]?.brand_id,sub:this.data.models[i]?.id}
      this.model_arr.push(this.model_obj)
      console.log("create array of objects Model [{cat:x , sub:y}]",this.model_arr)
     
  }
  
  for(let j=0 ; j<this.data.subcategories.length ; j++){
       this.subCats.push(this.data.subcategories[j].id) ;
       this.subcategory_obj={cat:this.data.subcategories[j]?.category_id , sub:this.data.subcategories[j]?.id}
       this.subcategory_arr.push(this.subcategory_obj)
    
  }

  this.subCats= this.convertArrayOfstringToArrayofInt(this.subCats)
  this.model_str=  this.convertArrofObjsToStr(this.model_arr)
  this.subcategory_str=  this.convertArrofObjsToStr(this.subcategory_arr)
    
  this.allCategories() ;
  this.allBrands() ;
  this.allYears();

  this.service.getSubcategoryByCategoryId(this.categories).subscribe((res:any)=>{
    this.subcategories_of_maincategories=res['data']
   })

  this.service.getModelsByBrandId(this.brands).subscribe((res:any)=>{
    this.models_of_brands=res['data']
   })
   
  this.form=this.formbuilder.group({
      name:[this.data.name,Validators.required],
      price:[this.data.price,Validators.required],
      discount_percent:[this.data.discount_percent,Validators.required],
      piece_number:[this.data.piece_number,Validators.required],
      manufacture_place:[this.data.manufacture_place,Validators.required],
      description:[this.data.description,Validators.required],
      compatibles: this.formbuilder.array([])
    })
    this.addCompat();

  }

get comps():any {
  return this.form.get("compatibles") as FormArray ; 
}
 
addCompat() {
  console.log("unique " , this.unique)
  this.comps.push(this.formbuilder.control(this.unique))
  
    this.form.value.compatibles=this.unique;
    this.items=this.form.value.compatibles;
    this.items=this.items[this.items.length-1]
  
    console.log("Form Add " , this.unique)
}

deleteCompatibles(index,model_id) {
  
    this.items=this.form.value.compatibles
    
    console.log(this.items)
    console.log(this.form.value.compatibles)
      
      
    this.unique= this.unique.filter(object => {
      return object.cat !=  model_id.value;
    });
    
  
    this.comps.removeAt(index) 
    console.log("Form Array 2 if" , this.unique)
    if( this.form.value.compatibles.length==0){ 
        this.unique.length=0
        console.log("Form Array 2 else " , this.unique)
      }
}

onChangeCompatibleProductModel(event){
  this.teamInitial = '';
 
  console.clear()
  console.log(event.value)

   this.product_compatibles_model= event.value
   this.form_compat_model.push(event.value)
   this.form_compat_model=this.form_compat_model.sort()
   console.log("MMMMMMMMM",this.form_compat_model)
   
}
  
onChangeCompatibleYear(event) {
  this.compat.length=0;
  this.product_compatibles_date=event.value
   
  console.clear()
  console.log(this.product_compatibles_model)
  console.log(this.product_compatibles_date)
 
  if(this.product_compatibles_date.length !=0 && this.product_compatibles_model.length!=0){
 
      for(let j=0 ; j<this.product_compatibles_date.length ; j++){
         this.compat.push({'cat':this.product_compatibles_model, 'sub':this.product_compatibles_date[j]})
         this.redundant_arr.push(this.compat[j])
        
         }
     }
    
      this.unique = this.redundant_arr.filter((obj, index) => {
        return index === this.redundant_arr.findIndex(o => obj.cat === o.cat && obj.sub === o.sub);
      });

 
  console.log("GG Year",  this.unique)
  this.form.value.compatibles=this.unique
   console.log("GG Arr Year", this.form.value.compatibles)
  
  
}

compareItems(optionOne,optionTwo){
    return optionOne.sub==optionTwo
}
 
compareModels(optionOne,optionTwo){
    return optionOne.sub==optionTwo.sub
}

compatModel(optionOne,optionTwo){
  return optionOne==optionTwo
}
 
convertArrayOfstringToArrayofInt(arr){
 var num =arr.map(function(str) {
     return parseInt(str); 
  });
return num ;
}

convertArrofObjsToStr(arrOfobjs){
    var subv=[]
    var newArray = arrOfobjs.reduce(function(acc, curr) {
      var findIfNameExist = acc.findIndex(function(item) {
         return item.cat === curr.cat;
       })
       if (findIfNameExist === -1) {
         let obj = {
           'cat': curr.cat,
           "value": [curr.sub]
         }
         acc.push(obj)
       } 
       else {
       acc[findIfNameExist].value.push(curr.sub)
       }
       return acc;
      }, []);
   
     console.log(newArray)
   
      for(var i=0 ; i<newArray.length ; i++){
        subv.push(newArray[i].value)
     }
 
     let finalStr = subv.map(a => a.join(",")).join('|');
  
    return finalStr;
}

allCategories(){
   this.service.allCategories().subscribe((res:any)=>{
    this.all_categories=res['data']
   })
}

onChangeCategory(event){
  this.changeCat =true;
 this.categories=event.value ;
 console.log("all selected categories" , this.categories)
 this.service.getSubcategoryByCategoryId(event.value).subscribe((res:any)=>{
 this.subcategories_of_maincategories=res['data']
})
}

onChangeSubCategory(event){
  this.subCategories =event.value
    console.log("all selected subCategories" , this.subCategories)
}

allBrands(){
  this.service.getBrands().subscribe((res:any)=>{
    this.all_brands=res['data']
   })
}

onChangeBrands(event){
  this.changeCat2=true
  this.brands=event.value ;
  console.log("all selected Brands",  this.brands)
   this.service.getModelsByBrandId(event.value).subscribe((res:any)=>{
   this.models_of_brands=res['data']
   console.log("asdfs",this.models_of_brands)
  })
}
 
onChangeModels(event){
  this.models =event.value
    console.log("all selected models" , this.models)
}
 
allYears(){
this.service.allYears().subscribe((res:any)=>{
   this.years=res['data']
  //  console.log("years" ,this.years )
})
}

onChangeManufactureDate(event){
  this.manufacture_date_id= event.value
  console.log("manufature year " , event.value)
}

description_files:File[] =[] ;
onSelectDescription_images(event) {
  this.image_edit2=true;
  console.log(event.addedFiles); 
  this.description_files.push(...event.addedFiles);
  event.addedFiles.forEach(file=>{
    console.log("fileSize",file,file.size)
    if(file.size>1048576) // 1MB 
    {
      Swal.fire( 
        `Fail`,
        `Image size must be 1 megabytes or less `,
        `error`)
    }
   })
  let images_form = {
    files:this.description_files
  }
  this.service.uploadFiles(images_form).subscribe((res:any)=>{
    this.d_images=res['data']
    console.log("description images ",this.d_images )
  })
}

onRemovedDescription_images(event) {
  console.log(event);
  this.description_files.splice(this.description_files.indexOf(event), 1);
}

files:File[] = [];
onSelect(event) {
 this.image_edit=true;
 console.log(event.addedFiles);
 this.files=[]
 this.files.push(...event.addedFiles);
 event.addedFiles.forEach(file=>{
  console.log("fileSize",file,file.size)
  if(file.size>1048576) // 1MB 
  {
    Swal.fire( 
      `Fail`,
      `Image size must be 1 megabytes or less `,
      `error`)
  }
 })
 
 let images_form = {
  files:this.files
}
 this.service.uploadFiles(images_form).subscribe((res:any)=>{
  this.images=res['data']
 })
}
 
onRemove(event) {
  console.log(event);
  this.files.splice(this.files.indexOf(event), 1);
}

convertArrayOfObjToStr(arrOfobjs){
  var subv=[]
  var newArray = arrOfobjs.reduce(function(acc, curr) {
    var findIfNameExist = acc.findIndex(function(item) {
       return item.cat === curr.cat;
     })
     if (findIfNameExist === -1) {
       let obj = {
         'cat': curr.cat,
         "value": [curr.sub]
       }
       acc.push(obj)
     } 
     else {
     acc[findIfNameExist].value.push(curr.sub)
     }
     return acc;
    }, []);
 
 
   let sortedProducts = newArray.sort((s1, s2) => {
     return s1.cat - s2.cat;
   });
  
    for(var i=0 ; i<newArray.length ; i++){
      subv.push(newArray[i].value)
   }
   console.log("subv",subv)
   let finalStr = subv.map(a => a.join(",")).join('|');
 
  return finalStr;
}

get f() {return this.form.controls}

submit(){
  this.submitted=true ;

  if(this.changeCat==true) {
  
  let arr = this.subCategories.map(object => object.cat);
  arr=this.convertArrayOfstringToArrayofInt(arr)

 let difference = this.categories.filter(x => arr.includes(x));
 this.categories=difference
  console.log("only categories of selected subcategories",this.categories)
 
  }
  
  if (this.changeCat2==true) {

    let arr2 = this.models.map(object => object.cat);
    arr2=this.convertArrayOfstringToArrayofInt(arr2)
   
   let difference2 = this.brands.filter(x => arr2.includes(x));
   this.brands=difference2
    console.log("only models of selected brands",this.brands)
  }
   
 // user change subcategories
 if(this.subCategories.length!=0) {
  this.subCategories.sort((a,b) => (a.cat > b.cat) ? 1 : ((b.cat > a.cat) ? -1 : 0))
  this.subcategory_str=this.convertArrofObjsToStr(this.subCategories)
 }
 // user change models
 if (this.models.length!=0) {
 this.models.sort((a,b) => (a.cat > b.cat) ? 1 : ((b.cat > a.cat) ? -1 : 0)) 
 this.model_str=this.convertArrofObjsToStr(this.models)
 }
///////////////
 
let compat_years = this.convertArrayOfObjToStr(this.unique)
console.log("compat_years compat_years compat_years compat_years ",compat_years )
///////////////
  
  let newData = {};
  Object.entries(this.form.value)
    .filter(([, value]) => value != null)
    .forEach(([key, value]) => (newData[key] = value));
  
  //  console.log("yyyyyyy",newData);
  console.log("yyyyyyy",this.form_compat_model)
  console.log("xxxxx",compat_years);
    let f={
      product_id:this.data.id ,
      image:this.images[0],
      ...newData,
       categories: this.categories,
      subcategories:this.subcategory_str ,
      brands:this.brands ,
      models:this.model_str ,
      product_compatibles: this.form_compat_model,
      product_compatibles_manufacture_date_ids:compat_years,
      // product_compatibles: this.product_compatibles_model,
      // product_compatibles_date:this.product_compatibles_date,
      desc_images:this.d_images
       
    }
    
   console.log('the sended form is ', f , this.subcategory_str ,this.model_str)
    this.spinner.show()
    this.service.editProducts(f).subscribe((res:any)=>{
    this.spinner.hide()
    console.log("editProducts",res)
    if(res.status==true){
     Swal.fire(
        `Success`,
        `${res.message}`,
        `success`
      )
    //   this.dialog.closeAll();
    //   console.log("dsddd",this.categories[0] , this.subcategory_str ,this.brands[0] ,this.model_str)
    //  this.router.navigate(['/app/products/lists',this.categories[0] , this.subcategory_str[0],this.brands[0] ,this.model_str[0] ]);
   
    }
    else {
      Swal.fire(
        `Fail`,
        `${res.errors[0]}`,
        `error`
      )
    }
    
    })
}

  }

 
