export const environment = {
  production: false,
  endpoint:'https://backend-production.ogetgroup.com/public/api',
  baseURL:'https://backend-production.ogetgroup.com/public/api',
  currentUserKey:'scaradmin',
  maxSize:'1024'
};

// endpoint:'https://backend-production.scarksa.com/public/api',
//   baseURL:'https://backend-production.scarksa.com/public/api',
 
// endpoint:'https://backend-test.scarksa.com/public/api',
// baseURL:'https://backend-test.scarksa.com/public/api',